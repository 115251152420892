export const getStationsObject = (stations) => {
  return stations.map((station) => getStationObject(station))
}

export const getStationObject = (station) => {
  let {
    id: stationId,
    stationNumber,
    branch,
    barStationNumber,
    isAlive,
    isDeliveryActive,
    isLaundryActive,
    isLockerRentActive,
    isLockerToLockerDeliveryActive,
    isDecathlon,
    minimumLaundryEmptyLockers,
    lockersNumber,
    price,
    lockers: stationLockers,
    latitude,
    longitude,
    availabilityHours,
    isDeliveryCompaniesActive,
    stationPaymentVersion,
    stationPriority,
    emptyLockersNum,
    brokenLockersNum,
    routeName,
    routeId,
    cityId,
    isAmot,
    isBLE,
    isYadMordechai,
    isSdeMoshe,
    isAmirim,
    isOutdoor,
    isVisible,
    nayaxTerminalSN,
    stationCodeBLE,
    postalCode,
    isReturnPackageActive,
    videoUrl,
    isReturnExelotActive,
    dhlStationNumber,
    dhlRouteCode,
    isApple,
    isTAU,
    routeAreaCodeId,
    isBerorHayil,
    storageSackId,
    nearByStationId,
    nearByStationId2,
    nearByStationId3,
    isAmshat,
    isMaayanZvi,
  } = station
  //console.log('station: '+ stationNumber);
  const branchId = branch ? branch.id : ""
  const branchName = branch ? branch.branchName : ""
  const branchAddress = branch ? branch.address : ""
  const branchName_en = branch ? branch.branchName_en : ""
  const branchAddress_en = branch ? branch.address_en : ""
  const branchDisplayAddress = branch ? branch.branchDisplayAddress : ""
  const branchDisplayName = branch ? branch.branchDisplayName : ""
  const priceListId = price ? price.priceListId : ""
  const priceListName = price ? price.priceName : "not set"

  stationLockers = stationLockers.map((locker) => {
    return {
      lockerId: locker.id,
      lockerName: locker.name,
      lockerStatus: locker.lockerStatus,
      lockerType: locker.lockerType,
      isAccessible: locker.isAccessible,
      lockerSize: locker.lockerSize,
    }
  })
  return {
    stationId,
    stationNumber,
    branchId,
    branchName,
    branchAddress,
    branchName_en,
    branchAddress_en,
    branchDisplayAddress,
    branchDisplayName,
    barStationNumber,
    isAlive,
    isDeliveryActive,
    isLaundryActive,
    isLockerRentActive,
    isLockerToLockerDeliveryActive,
    isDecathlon,
    minimumLaundryEmptyLockers,
    lockersNumber,
    priceListId,
    priceListName,
    stationLockers,
    latitude,
    longitude,
    availabilityHours,
    isDeliveryCompaniesActive,
    stationPaymentVersion,
    stationPriority,
    emptyLockersNum,
    brokenLockersNum,
    routeName,
    routeId,
    cityId,
    isAmot,
    isBLE,
    isYadMordechai,
    isSdeMoshe,
    isAmirim,
    isOutdoor,
    isVisible,
    nayaxTerminalSN,
    stationCodeBLE,
    postalCode,
    isReturnPackageActive,
    videoUrl,
    isReturnExelotActive,
    dhlStationNumber,
    dhlRouteCode,
    isApple,
    isTAU,
    routeAreaCodeId,
    isBerorHayil,
    storageSackId,
    nearByStationId,
    nearByStationId2,
    nearByStationId3,
    isAmshat,
    isMaayanZvi,
  }
}
