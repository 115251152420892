import React, { Component } from "react"
import { connect } from "react-redux"

import {
  WhiteFrame,
  Title,
  BlueButton,
  CheckboxAndLabel,
  SelectValPassive,
  Spinner,
} from "../../../common"
import {
  updateOrderData,
  printPinkLabel,
  printHydroLabel,
  printWhiteReceipt,
  fetchAllOrders,
  getOrderDetails,
  updateUserCompensation,
  sendReadyInLockerSMS,
  setSelectedCoupon,
  fetchCouponById,
  setEmptyCoupon,
  addOrderCoupon,
  removeOrderCoupon,
  fetchActiveOrderUserCoupons,
  getAllSuppliers,
  fetchOrderTypes,
  getOrderItemsOriginal,
  recalcOrderNoSave,
  clearRecalculated,
  clearCalculateError,
  sendDelaySMS,
  fetchAccountTypes,
  sendInLockerReturnSMS,
  sendSMSForChangeStation,
  updateOrderStatusAndExtentionDate,
  clearOrderInLockerExtend,
  resetDeliveryLoginCode,
  clearDeliveryLoginCodeReset,
  setTargetStationChangeState,
  onStartOrdersLoading,
  fetchDoneOrderTypes,
  clearChangeTargetStation,
  startReturnToSender,
  getOrderStorageStatuses,
} from "../../../../actions"
import { fetchIfEmpty } from "../../../../utils"
import CustomerDetailsFrame from "./CustomerDetailsFrame"
import OrderItemsFrame from "./OrderItemsFrame"
import OrderDetails from "./OrderDetails"
import YesNoModal from "../../../Modals/YesNoModal"
import NotificationModal from "../../../Modals/NotificationModal"
import OrderHistoryModal from "../../../Modals/OrderHistoryModal"
import OrderItemsOriginalModal from "../../../Modals/OrderItemsOriginalModal"
import OrderNotesModal from "../../../Modals/OrderNotesModal"
import { getFormatedDate } from "../../../../utils/FormatedDate"
import OrderImagesModal from "../../../Modals/OrderImagesModal/OrderImagesModal"
import OrderInLockerExtendModal from "../../../Modals/OrderInLockerExtendModal"
import CreateOrderHistoryNoteModal from "../../../Modals/CreateOrderHistoryNoteModal"
import SelectStationChangeModal from "../../../Modals/SelectStationChangeModal"
import SelectStationSourceChangeModal from "../../../Modals/SelectStationSourceChangeModal"
import OrderTrackingModal from "../../../Modals/OrderTrackingModal"
import {
  calcListPriceNoCompensationWithVat,
  calcPriceAfterDiscountWithVat,
} from "../../../../utils"
import "./style.scss"
import SelectCouponModal from "../../../Modals/SelectCouponModal"
import Select from "react-select"

const paidOptions = ["לא", "כן"]

class OrderEditor extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      updatedOrder: null,
      printLabelIsChecked: false,
      printReceiptIsChecked: false,
      sendEmailSms: true,
      showCancelOrderModal: false,
      showPaidAreYouSureModal: false,
      showStatusChangeAreYouSureModal: false,
      showCommentsOnUserModal: false,
      showCommentsOnOrderModal: false,
      isShowHistory: false,
      showRemoveCouponAreYouSureModal: false,
      showAddCouponModal: false,
      showCantSetStatusModal: false,
      showCantSetStatusModal2: false,
      showWillReturnToWarehouse: false,
      supplierName: null,
      suppliers: null,
      isShowOrderItemsOriginal: false,
      orderItemsOriginal: [],
      showOrderItemValidation: false,
      isHighPriority: false,
      isShowLaundryImages: false,
      showCheapSimNoUpdate: false,
      showOrderInLockerExtendModal: false,
      showOrderHistoryNoteModal: false,
      showConfirmResetDeliveryLoginCodeModal: false,
      showStationChangeStateModal: false,
      showStationSourceChangeStateModal: false,
      showReturnToSourceModal: false,
      isTracking: false,
      showOrderTrackingModal: false,
      isStationChangeBilling: false,
      showNoItemsMsg: false,
    }

    this.state = this.INIT_STATE
    this.setViewHistory = this.setViewHistory.bind(this)
  }

  componentWillMount() {
    const { order, orderDetails, userDetails } = this.props
    this.props.getAllSuppliers()
    this.props.fetchAccountTypes()
    this.props.getOrderStorageStatuses()
    this.setState({
      updatedOrder: { ...orderDetails },
      showCommentsOnUserModal: this.showUserComments(userDetails),
      showCommentsOnOrderModal: this.showOrderNotes(orderDetails),
    })

    fetchIfEmpty(this.props.orderTypes, this.props.fetchOrderTypes)
    fetchIfEmpty(this.props.doneOrderTypes, this.props.fetchDoneOrderTypes)
  }

  componentWillUnmount() {
    this.setState({
      updatedOrder: null,
      printLabelIsChecked: false,
      printReceiptIsChecked: false,
      sendEmailSms: true,
      showCancelOrderModal: false,
      showPaidAreYouSureModal: false,
      showStatusChangeAreYouSureModal: false,
      showCommentsOnUserModal: false,
      showCommentsOnOrderModal: false,
      isShowHistory: false,
      showRemoveCouponAreYouSureModal: false,
      showAddCouponModal: false,
      showCantSetStatusModal: false,
      showCantSetStatusModal2: false,
      showWillReturnToWarehouse: false,
      supplierName: null,
      suppliers: null,
      isShowOrderItemsOriginal: false,
      orderItemsOriginal: [],
      showOrderItemValidation: false,
      isHighPriority: false,
      isFixedLocationBackoffice: false,
      showOrderHistoryNoteModal: false,
      showConfirmResetDeliveryLoginCodeModal: false,
      isTracking: false,
      showOrderTrackingModal: false,
    })
  }

  refreshData() {
    this.props.getOrderDetails(this.props.orderDetails.id)
    this.props.clearChangeTargetStation()
  }

  componentWillReceiveProps(newProps) {
    const {
      orderDetails,
      userDetails,
      suppliersList,
      isRecalculated,
      orderInLockerExtendSuccess,
    } = newProps || null
    const { orderDetails: oldOrderDetails, userDetails: oldUserDetails } =
      this.props
    const { updatedOrder } = this.state

    if (!orderDetails) {
      console.log("newProps: orderDetails=" + orderDetails)
      return
    }

    if (newProps.changeTargetStationSuccess) {
      this.refreshData()
      return
    }

    const userDetailsMatchesOrderDetails =
      userDetails && orderDetails.customerId === userDetails.id
    //TODO MATCH FOR LOCKER TO LOCKER

    if (
      //---------------------- Different order
      !oldOrderDetails ||
      (orderDetails &&
        oldOrderDetails &&
        orderDetails.id !== oldOrderDetails.id) ||
      !oldUserDetails ||
      (userDetails && oldUserDetails && userDetails.id !== oldUserDetails.id)
    ) {
      //const setChecked = orderDetails.orderType === 1 || orderDetails.orderType === 5
      this.setState({
        ...this.INIT_STATE,
        updatedOrder: { ...orderDetails },
        showCommentsOnUserModal:
          this.showUserComments(userDetails) && userDetailsMatchesOrderDetails,
        showCommentsOnOrderModal:
          this.showOrderNotes(orderDetails) && userDetailsMatchesOrderDetails,
        // printReceiptIsChecked: setChecked,
        //printLabelIsChecked: setChecked,
        isHighPriority: orderDetails.isHighPriority,
        isTracking: orderDetails.isTracking,
      })

      this.setPrintCheckboxes()

      if (orderDetails && orderDetails.couponId != null) {
        this.props.fetchCouponById(orderDetails.couponId)
      } else {
        this.props.setEmptyCoupon()
      }
    } //---------------------- Different order

    if (suppliersList) {
      const suppliersFiltered = suppliersList.map((supplier, index) => ({
        value: index,
        label: supplier.name,
        id: supplier.id,
      }))

      this.setState({
        suppliers: suppliersFiltered,
        supplierName: this.getCurrentSupplierName(orderDetails, suppliersList),
      })
    }

    if (
      orderDetails &&
      oldOrderDetails &&
      orderDetails.id === oldOrderDetails.id &&
      (isRecalculated || orderDetails !== oldOrderDetails)
    ) {
      //---------------------- Recalculated order
      this.setState({
        updatedOrder: { ...orderDetails },
        isHighPriority: orderDetails.isHighPriority,
        isTracking: orderDetails.isTracking,
      })
      this.props.clearRecalculated()
      if (orderDetails && orderDetails.couponId != null) {
        this.props.fetchCouponById(orderDetails.couponId)
      } else {
        this.props.setEmptyCoupon()
      }
      if (orderDetails.couponId !== oldOrderDetails.couponId) {
        console.log("couponId is updated")
        this.props.fetchActiveOrderUserCoupons(
          this.props.userDetails.id,
          orderDetails
        )
      }
    }
  }

  setPrintCheckboxes() {
    const { orderDetails } = this.props
    if (
      orderDetails?.orderType != 12 &&
      (orderDetails?.orderStatus == 1 || orderDetails?.orderStatus == 19)
    ) {
      this.setState({
        printReceiptIsChecked: true,
        printLabelIsChecked: true,
      })
    }
  }

  getCurrentSupplierName(orderDetails, suppliersList) {
    const sup = suppliersList.find(
      (o) => o.id === orderDetails.serviceSupplierId
    )
    if (sup) {
      return sup.name
    }
  }

  showUserComments(userDetails) {
    let result = false

    const { comment } = userDetails || false

    if (comment && comment !== "") {
      result = true
    }

    return result
  }

  showOrderNotes(orderDetails) {
    const { orderItems } = orderDetails || false
    if (orderItems == null) {
      return false
    }
    let notes = orderItems.filter((i) => i.notes != null && i.notes.length > 0)
    return notes.length > 0
  }

  onOrderChanged(order) {
    //this.whatIsAChange(this.state.updatedOrder, order)//debugger
    this.setState({ updatedOrder: { ...order } })
    this.props.recalcOrderNoSave(order)
  }

  onOrderCancelClick() {
    this.setState({ showCancelOrderModal: true })
  }

  cancelCurrentOrder() {
    const { updatedOrder } = this.state

    updatedOrder.orderStatus = 6 // 6 -> CANCELED ORDER..

    this.setState({
      updatedOrder,
      showCancelOrderModal: false,
      showCancelOrderHistoryNoteModal: true,
    })

    //this.props.updateOrderData(updatedOrder)
  }

  isOrderItemsNameMissing(orderItems) {
    for (let x in orderItems) {
      if (orderItems[x].processName === "Compensation") {
        continue
      }
      if (
        orderItems[x].itemName === null ||
        orderItems[x].itemName === undefined ||
        orderItems[x].itemName === ""
      ) {
        this.setState({ showOrderItemValidation: true })
        return true
      }
    }
  }

  onSubmitOrderChanges(sendEmailSms) {
    let {
      updatedOrder,
      printLabelIsChecked,
      printReceiptIsChecked,
      isHighPriority,
      isTracking,
    } = this.state
    const { orderDetails } = this.props

    if (orderDetails.orderType === 1) {
      let isOrderItemsNameMissing = this.isOrderItemsNameMissing(
        orderDetails.orderItems
      )
      if (isOrderItemsNameMissing) {
        return
      }
    }

    const isChangeStatusAllowed = this.isAllowChangeStatus(updatedOrder)
    if (!isChangeStatusAllowed) {
      return
    }

    if (orderDetails.orderStatus === updatedOrder.orderStatus) {
      if (updatedOrder.orderStatus === 1) {
        if (orderDetails.orderType === 1) {
          updatedOrder.orderStatus = 17
          this.onSubmitOrderChanges()
          return
        }
        if (orderDetails.orderType === 5) {
          updatedOrder.orderStatus = 3
          this.setState({ updatedOrder, showStatusChangeAreYouSureModal: true })
          return
        }
        if (orderDetails.orderType === 18) {
          updatedOrder.orderStatus = 12
          this.setState({ updatedOrder, showStatusChangeAreYouSureModal: true })
          return
        }
      }
      if (updatedOrder.orderStatus === 19 && orderDetails.orderType === 18) {
        updatedOrder.orderStatus = 3
        this.setState({ updatedOrder, showStatusChangeAreYouSureModal: true })
        return
      }
      if (updatedOrder.orderStatus === 19 && orderDetails.orderType === 8) {
        updatedOrder.orderStatus = 3
        this.setState({ updatedOrder, showStatusChangeAreYouSureModal: true })
        return
      }
      if (updatedOrder.orderStatus === 1 && orderDetails.orderType === 21) {
        updatedOrder.orderStatus = 12
        this.setState({ updatedOrder, showStatusChangeAreYouSureModal: true })
        return
      }
    }
    if (!updatedOrder) {
      updatedOrder = this.props.orderDetails
    }

    updatedOrder.isHighPriority = isHighPriority
    updatedOrder.isTracking = isTracking

    this.props.updateOrderData(
      updatedOrder,
      sendEmailSms,
      printLabelIsChecked,
      printReceiptIsChecked
    )
  }

  isAllowChangeStatus(updatedOrder) {
    if (
      updatedOrder.orderStatus === 4 &&
      this.props.orderDetails.orderStatus != 13 &&
      this.props.orderDetails.orderStatus != 4
    ) {
      this.setState({ showCantSetStatusModal: true })
      return false
    }
    if (
      updatedOrder.orderStatus === 0 &&
      this.props.orderDetails.orderStatus == 4
    ) {
      this.setState({ showCantSetStatusModal: true })
      return false
    }
    if (
      this.props.orderDetails.orderStatus === 13 &&
      this.props.orderDetails.orderType === 1
    ) {
      this.setState({ showWillReturnToWarehouse: true })
      return true
    }
    if (updatedOrder.accountType == 14 && updatedOrder.orderStatus === 19) {
      this.setState({ showCantSetStatusModal2: true })
      return false
    }
    return true
  }

  printPinkLabel() {
    const { orderNumber, firstName, lastName } = this.props.orderDetails
    const printObject = {
      name: firstName + " " + lastName,
      orderNumber: orderNumber,
      printNum: 1,
    }
    this.props.printHydroLabel(printObject)
  }

  printWhiteReceipt() {
    this.props.printWhiteReceipt(this.props.orderDetails)
  }

  isDiscountTypePercentage(updatedOrder) {
    return !updatedOrder || updatedOrder.discountType === 0
  }

  onOrderStatusChange(status) {
    const { updatedOrder } = this.state

    if (updatedOrder.orderType === 1 && status === 13) {
      this.setState({ showWillReturnToWarehouse: true })
      updatedOrder.orderStatus = status
      this.setState({ printReceiptIsChecked: false, updatedOrder })
      return
    }
    if (updatedOrder.orderType === 18) {
      if (status === 12) {
        this.setState({
          printReceiptIsChecked: false,
        })
      } else {
        this.setState({
          printReceiptIsChecked: true,
        })
      }
    }
    updatedOrder.orderStatus = status
    this.setState({ updatedOrder })
  }

  onOrderSupplierChange(supllier) {
    const { suppliersList } = this.props
    const { updatedOrder } = this.state
    const sup = suppliersList.find((o) => o.name === supllier)
    if (sup) {
      updatedOrder.serviceSupplierId = sup.id
      this.setState({ updatedOrder })
    }
  }

  onOrderPaymentStatusChange(status) {
    const { updatedOrder } = this.state

    if (!status) {
      updatedOrder.isPayed = false
    } else {
      this.setState({ showPaidAreYouSureModal: true })
      return
    }

    this.setState({ updatedOrder })
  }

  changeOrderPaymentStatusToPaid() {
    const { updatedOrder } = this.state

    updatedOrder.isPayed = true

    this.setState({
      updatedOrder,
      showPaidAreYouSureModal: false,
    })
  }

  onSaveAndApplyClick(sendEmailSms) {
    let { updatedOrder } = this.state
    const { orderDetails } = this.props
    if (
      updatedOrder?.orderItems.length === 0 ||
      updatedOrder?.orderItems === null
    ) {
      this.setState({ showNoItemsMsg: true })
      return
    }
    if (
      updatedOrder.accountType === 14 &&
      orderDetails.orderStatus === 19 &&
      updatedOrder.orderStatus === 3
    ) {
      this.setState({ showCheapSimNoUpdate: true })
      return
    }

    if (updatedOrder.orderStatus != 4) {
      if (orderDetails.orderStatus !== updatedOrder.orderStatus) {
        this.setState({ showStatusChangeAreYouSureModal: true })
        return
      }
    }
    this.onSubmitOrderChanges(sendEmailSms)
  }

  applyWithSms() {
    const sendEmailSms = true
    this.onSaveAndApplyClick(sendEmailSms)
  }

  onStatusChangeAreYouSureModalOkClick() {
    console.log("OrderEditor> onStatusChangeAreYouSureModalOkClick>")
    this.setState({
      showStatusChangeAreYouSureModal: false,
      showOrderHistoryNoteModal: true,
    })
    //this.onSubmitOrderChanges()
  }

  onFillHistoryNoteClick(note) {
    console.log("OrderEditor> onFillHistoryNoteClick> note:")
    console.log(note)
    const { updatedOrder } = this.state

    updatedOrder.historyNote = note

    this.setState({
      updatedOrder,
      showOrderHistoryNoteModal: false,
    })

    this.onSubmitOrderChanges()
  }

  onCancelOrderFillHistoryNoteClick(note) {
    console.log("OrderEditor> onCancelOrderFillHistoryNoteClick> note:")
    console.log(note)
    const { updatedOrder } = this.state

    updatedOrder.historyNote = note

    this.setState({
      updatedOrder,
      showCancelOrderHistoryNoteModal: false,
    })
    this.props.updateOrderData(updatedOrder)
  }

  onCompensationStateUpdate(isOn) {
    const { updatedOrder } = this.state
    updatedOrder.isAllowedCompensation = isOn

    this.props.recalcOrderNoSave(updatedOrder)
  }

  setViewHistory(isShow) {
    this.setState({ isShowHistory: isShow })
  }

  getOrderNotes(orderDetails) {
    const { orderItems } = orderDetails || false
    if (orderItems == null) {
      return false
    }
    let itemHaveNotes = orderItems.filter(
      (i) => i.notes != null && i.notes.length > 0
    )
    if (itemHaveNotes.length <= 0) {
      return false
    }
    let res = itemHaveNotes.map((i) => {
      return i.notes
    })
    return res
  }

  onCloseCouponModal() {
    this.setState({ showAddCouponModal: false })
  }

  onSelectedCouponToAddToOrder(coupon) {
    if (this.state.updatedOrder.isPayed) {
      //alert("הזמנה כבר שולמה. לא ניתן להוסיף קופון")
      return
    }
    this.onCloseCouponModal()
    this.props.setSelectedCoupon(coupon)
    this.props.addOrderCoupon(coupon.couponId, this.state.updatedOrder)
  }

  onAprooveRemoveCouponClick() {
    if (this.state.updatedOrder.isPayed) {
      return
    }
    this.setState({ showRemoveCouponAreYouSureModal: false })
    this.props.setEmptyCoupon()
    this.props.removeOrderCoupon(
      this.props.orderDetails.id,
      this.state.updatedOrder
    )
  }

  statusNameFromObj(statusesObj, orderStatus) {
    let statusName = ""
    statusesObj.filter((obj) => {
      if (obj.value === orderStatus) {
        statusName = obj.label
      }
    })
    return statusName
  }

  setDefaultSupplier(suppliers) {
    let { supplierName, updatedOrder } = this.state
    if (supplierName) {
      return suppliers.find(
        (option) => option.label === this.state.supplierName
      )
    } else if (!updatedOrder.serviceSupplierId) {
      const sup = suppliers.find(
        (option) => option.id === this.props.orderDetails.routeServiceSupplierId
      )
      if (sup) {
        updatedOrder.serviceSupplierId = sup.id
        this.setState({ updatedOrder })
        return sup
      }
    }
  }

  setDefaultStation(sortedStationsNames) {
    const { orderDetails } = this.props

    if (orderDetails && orderDetails.orderType === 5) {
      return sortedStationsNames.find(
        (option) => option.stationId === orderDetails.targetStationId
      )
    } else {
      return sortedStationsNames.find(
        (option) => option.stationId === orderDetails.stationId
      )
    }
  }

  onOrderStationChange(stationId) {
    const { updatedOrder } = this.state
    if (!stationId) {
      return
    }
    if (updatedOrder.orderType == 5) {
      updatedOrder.targetStationId = stationId
      this.setState({ updatedOrder })
      return
    }
    updatedOrder.stationId = stationId
    this.setState({ updatedOrder })
  }

  showOrderItemsOriginalModal(isShow) {
    this.props.getOrderItemsOriginal(this.props.orderDetails.id)
    this.setState({ isShowOrderItemsOriginal: isShow })
  }

  showOrderImagesModal(isShow) {
    this.setState({ isShowLaundryImages: isShow })
  }

  showIsNeedAccessibility(show) {
    if (show) {
      return (
        <div className="is-need-accessibility">
          <div className="fa fa-wheelchair" aria-hidden="true"></div>
          <div className="label">נדרש נגישות</div>
        </div>
      )
    }
  }

  showOptionalWrongDoor(doors) {
    if (doors && doors.length > 0) {
      return (
        <div className="optional-wrong-door">
          <div className="fa fa-exclamation-triangle" aria-hidden="true"></div>
          <div className="label">יש סיכוי לדלת שגויה</div>
          <div className="label">(תאים לבדיקה: {doors})</div>
        </div>
      )
    }
  }

  renderDate(show, title, value) {
    if (!show) {
      return
    }

    return (
      <div className="order-editor-details-frame">
        <Title extraClass="order-editor-details-frame-title">{title}:</Title>
        <Title bold extraClass="order-editor-details-frame-value">
          {value}
        </Title>
      </div>
    )
  }

  inLockerDateExtend() {
    this.setState({ showOrderInLockerExtendModal: true })
  }

  confirmResetDeliveryLoginCode() {
    this.setState({ showConfirmResetDeliveryLoginCodeModal: true })
  }

  updateInLockerDate(data) {
    const { updatedOrder } = this.state
    const dateWithTime = new Date(data?.selectedDate)
    dateWithTime.setHours(23)
    dateWithTime.setMinutes(0)
    const unixTimestamp = Math.floor(dateWithTime.getTime() / 1000)
    const newStatus =
      updatedOrder.orderStatus === 13 ? 4 : updatedOrder.orderStatus
    this.props.updateOrderStatusAndExtentionDate(
      this.state.updatedOrder?.id,
      newStatus,
      unixTimestamp,
      data?.isBilling
    )
  }

  clearAndRefreshOrder() {
    this.props.getOrderDetails(this.props.orderDetails.id)
    this.props.clearDeliveryLoginCodeReset()
  }

  onCloseOrderInLockerExtendModal() {
    this.setState({ showOrderInLockerExtendModal: false })
    this.props.clearOrderInLockerExtend()
  }

  onConfirmResetDeliveryCode() {
    this.props.resetDeliveryLoginCode(this.props.orderDetails.id)
    this.setState({ showConfirmResetDeliveryLoginCodeModal: false })
  }

  setTargetStationChangeState(
    orderId,
    targetStationId,
    isStationChangeBilling
  ) {
    this.setState({
      showStationChangeStateModal: false,
    })
    this.props.onStartOrdersLoading()
    this.props.setTargetStationChangeState(
      orderId,
      targetStationId,
      isStationChangeBilling
    )
  }

  onConfirmReturnToSource() {
    this.props.onStartOrdersLoading()
    this.props.startReturnToSender(this.props.orderDetails.id)
    this.setState({ showReturnToSourceModal: false })
  }

  render() {
    const {
      ordersListArr,
      orderDetails,
      userDetails,
      statusesObj,
      isUpdateOrderLoading,
      coupon,
      orderTypes,
      doneOrderTypes,
      sortedStationsNames,
      orderItemsOriginal,
      calculateError,
      accountTypes,
      orderStorageStatuses,
    } = this.props
    const {
      sendEmailSms,
      showRemoveCouponAreYouSureModal,
      showAddCouponModal,
      printLabelIsChecked,
      printReceiptIsChecked,
      updatedOrder,
      showCommentsOnUserModal,
      showCommentsOnOrderModal,
      showCancelOrderModal,
      showPaidAreYouSureModal,
      showStatusChangeAreYouSureModal,
      showCantSetStatusModal,
      showCantSetStatusModal2,
      showWillReturnToWarehouse,
      showOrderItemValidation,
      suppliers,
      isHighPriority,
      showCheapSimNoUpdate,
      showOrderHistoryNoteModal,
      showCancelOrderHistoryNoteModal,
      showConfirmResetDeliveryLoginCodeModal,
      showStationChangeStateModal,
      showStationSourceChangeStateModal,
      showReturnToSourceModal,
      isTracking,
      showOrderTrackingModal,
      showNoItemsMsg,
    } = this.state

    if (!orderDetails) {
      return (
        <div className="order-editor-spinner-wrapper">
          <Spinner zoom={2} />
        </div>
      )
    }

    const userId = userDetails ? userDetails.id : null

    if (!ordersListArr) {
      return <div>LOADING...</div>
    }

    const { compensation } = userDetails || 0
    let {
      orderType,
      orderStatus,
      orderDate,
      orderConfirmDate,
      dirtyOrderDate,
      cleanOrderDate,
      preCreateOrderRequestDate, //0001-01-01T00:00:00
      outForServiceDate,
      inWarehouseDate,
      isPayed,
      isAllowedCompensation,
      orderTotalPrice,
      discount,
      discountType,
      isNeedAccessibility,
      optionalWrongDoor,
      orderDueDate,
      isFixedLocationBackoffice,
      isFixedLocation,
      isReturnToSource,
      IsTracking,
    } = updatedOrder || 0

    const { stationChangeState } = orderDetails

    let { orderItems } = updatedOrder || []
    orderItems = orderItems ? orderItems : []

    let noCompensatonItems = orderItems.filter(
      (item) => item.processName !== "Compensation"
    )

    const orderPriceBeforeDiscount =
      calcListPriceNoCompensationWithVat(orderItems)
    const orderPriceAfterDiscount = calcPriceAfterDiscountWithVat(
      noCompensatonItems,
      discountType,
      discount
    )

    orderDate = getFormatedDate(orderDate)
    orderDueDate = getFormatedDate(orderDueDate)
    dirtyOrderDate = getFormatedDate(dirtyOrderDate)
    cleanOrderDate = getFormatedDate(cleanOrderDate)

    outForServiceDate = getFormatedDate(outForServiceDate)
    outForServiceDate =
      outForServiceDate !== ""
        ? outForServiceDate
        : getFormatedDate(inWarehouseDate)
    preCreateOrderRequestDate = getFormatedDate(preCreateOrderRequestDate)
    orderConfirmDate = getFormatedDate(orderConfirmDate)

    const isConfirmedOrder = orderDate !== orderConfirmDate
    const havePreCreateOrderRequest = preCreateOrderRequestDate !== ""

    const orderConfirmDateToShow =
      havePreCreateOrderRequest && !isConfirmedOrder
        ? "הזמנה לא בוצע עדיין"
        : orderConfirmDate

    const isDiscountTypePercent = this.isDiscountTypePercentage(updatedOrder)

    const isDoneOrderType =
      doneOrderTypes &&
      doneOrderTypes.length > 0 &&
      doneOrderTypes.find((value) => value === orderType)

    const { firstName, lastName, comment } = userDetails || ""

    const canChangeTargetStation =
      orderStatus === 3 ||
      orderStatus === 18 ||
      orderStatus === 19 ||
      (orderType === 5 && (orderStatus === 0 || orderStatus === 1)) ||
      (orderType === 1 && (orderStatus === 1 || orderStatus === 17))

    return (
      <div className="order-editor-wrapper">
        <CustomerDetailsFrame
          order={updatedOrder}
          user={userDetails}
          coupon={coupon}
          onRemoveCoupon={() =>
            this.setState({ showRemoveCouponAreYouSureModal: true })
          }
          onAddCoupon={() => this.setState({ showAddCouponModal: true })}
        />

        <OrderDetails
          order={updatedOrder}
          orderTypes={orderTypes}
          accountTypes={accountTypes}
        />

        <div className="select-holder">
          {orderDetails?.orderType === 1 ? (
            <div className="suppliers-List" key={suppliers}>
              <span className="supplier-label">ספק: </span>
              <Select
                className="supplier-selector"
                defaultValue={
                  suppliers ? this.setDefaultSupplier(suppliers) : null
                }
                options={suppliers}
                onChange={(evt) => {
                  this.onOrderSupplierChange(evt.label)
                }}
              />
            </div>
          ) : null}
          <div>
            <CheckboxAndLabel
              checked={isHighPriority}
              onChange={(e) =>
                this.setState({ isHighPriority: e.target.checked })
              }
            >
              הזמנה בעדיפות גבוהה
            </CheckboxAndLabel>
          </div>
          <BlueButton
            extraClass="btn-tracking"
            onClick={(e) => this.setState({ showOrderTrackingModal: true })}
          >
            {updatedOrder.isTracking ? "הזמנה במעקב" : " עקוב אחרי הזמנה"}
          </BlueButton>
          <div>
            <OrderTrackingModal
              isShow={showOrderTrackingModal}
              className="order-tracking-open"
              order={updatedOrder}
              close={() => this.setState({ showOrderTrackingModal: false })}
            ></OrderTrackingModal>
          </div>
          {this.showIsNeedAccessibility(isNeedAccessibility)}
          {updatedOrder && updatedOrder.accountType === 9 && (
            <>
              <div>
                <CheckboxAndLabel
                  checked={isFixedLocationBackoffice}
                  onChange={(e) => {
                    updatedOrder.isFixedLocationBackoffice = e.target.checked
                    this.setState({
                      isFixedLocationBackoffice: e.target.checked,
                      updatedOrder,
                    })
                  }}
                >
                  לטעון רק בעמדת לקוח
                </CheckboxAndLabel>
              </div>

              <div className="is-fixed-location">
                <CheckboxAndLabel checked={isFixedLocation} disabled>
                  עמדה שנבחרה ע"י לקוח
                </CheckboxAndLabel>
              </div>
            </>
          )}

          {isDoneOrderType &&
          isReturnToSource === false &&
          (orderStatus === 4 || orderStatus === 13) ? (
            <div className="station-change-state">
              <BlueButton
                extraClass="blbtn-wrapper btn-items-original"
                onClick={(e) =>
                  this.setState({ showStationChangeStateModal: true })
                }
              >
                שינויי עמדת יעד
              </BlueButton>
              {stationChangeState === true ? (
                <>
                  <span className="station-change-state-ind-arrow"></span>
                  <span className="station-change-state-ind"> הופעל</span>
                </>
              ) : null}
            </div>
          ) : null}
          {orderType == 5 && orderStatus == 18 ? (
            <BlueButton
              extraClass="blbtn-wrapper btn-items-original"
              onClick={(e) =>
                this.setState({ showStationSourceChangeStateModal: true })
              }
            >
              שינויי עמדת מקור
            </BlueButton>
          ) : null}
          {isReturnToSource === false &&
          orderType === 5 &&
          (orderStatus === 4 || orderStatus === 13) ? (
            <BlueButton
              extraClass="blbtn-wrapper btn-items-original is-return-to-source"
              onClick={(e) => this.setState({ showReturnToSourceModal: true })}
            >
              להחזיר לשולח
            </BlueButton>
          ) : null}
          {canChangeTargetStation ? (
            <div className="station-List">
              <span className="station-label">עמדת יעד: </span>
              <Select
                className="station-selector"
                defaultValue={
                  sortedStationsNames
                    ? this.setDefaultStation(sortedStationsNames)
                    : null
                }
                options={sortedStationsNames}
                onChange={(evt) => {
                  this.onOrderStationChange(evt.stationId)
                }}
              />
            </div>
          ) : null}
          {this.showOptionalWrongDoor(optionalWrongDoor)}
        </div>

        <div className="order-editor-date-frames-container">
          <WhiteFrame extraClass="order-editor-frame-wrapper">
            {this.renderDate(
              havePreCreateOrderRequest,
              "יצירת בקשה להזמנה",
              preCreateOrderRequestDate
            )}
            {this.renderDate(true, "ביצוע הזמנה", orderConfirmDateToShow)}
            {this.renderDate(true, 'איסוף מלוקר ע"י נהג', dirtyOrderDate)}
            {this.renderDate(true, "קליטה במערכת המיון", outForServiceDate)}
            {this.renderDate(true, "מוכן לטעינה", cleanOrderDate)}
          </WhiteFrame>

          <WhiteFrame extraClass="order-editor-single-date-wrapper">
            <div className="order-editor-details-frame">
              <Title extraClass="order-editor-details-frame-title">
                תאריך יעד :
              </Title>
              <Title
                bold
                extraClass="order-editor-details-frame-value order-editor-green-value"
              >
                {orderDueDate}
              </Title>
            </div>
          </WhiteFrame>
        </div>

        <div className="order-editor-buttons-wrap">
          <div>
            <div
              className="order-history-button"
              onClick={() => this.setViewHistory(true)}
              alt="OrderHistory"
            >
              היסטוריה של הזמנה
            </div>
            <OrderHistoryModal
              isShow={this.state.isShowHistory}
              order={orderDetails}
              accountType={
                accountTypes && accountTypes[orderDetails?.accountType]
              }
              orderType={orderTypes && orderTypes[orderDetails?.orderType]}
              setViewHistory={this.setViewHistory}
              statuses={statusesObj}
              storageStatuses={orderStorageStatuses}
              getFormatedDate={getFormatedDate}
            />
            <OrderItemsOriginalModal
              isShow={this.state.isShowOrderItemsOriginal}
              orderNumber={orderDetails.orderNumber && orderDetails.orderNumber}
              orderItems={orderItemsOriginal}
              setViewOrderItemsOriginal={() =>
                this.showOrderItemsOriginalModal()
              }
            />
            {this.state.isShowLaundryImages ? (
              <OrderImagesModal
                show={this.state.isShowLaundryImages}
                order={orderDetails}
                close={(e) => this.setState({ showLaundryImages: false })}
                showOrderImagesModal={() => this.showOrderImagesModal()}
              />
            ) : null}
          </div>
          <div className="order-editor-status">
            סטטוס ההזמנה:
            <Select
              className="selvalpassive-wrapper order-editor-status-val"
              defaultValue={
                statusesObj
                  ? statusesObj.find((option) => option.value === orderStatus)
                  : null
              }
              options={statusesObj}
              onChange={(status) => this.onOrderStatusChange(status.value)}
            />
            שולם:
            <SelectValPassive
              items={paidOptions}
              onItemSelected={(status) =>
                this.onOrderPaymentStatusChange(paidOptions.indexOf(status))
              }
              valueIndex={isPayed ? 1 : 0}
              extraClass="order-editor-status-val"
            />
            <div />
          </div>
          <BlueButton
            extraClass="order-editor-btn order-editor-btn-red"
            onClick={(e) => this.onOrderCancelClick()}
          >
            בטל הזמנה
          </BlueButton>
        </div>

        <OrderItemsFrame
          order={orderDetails}
          onOrderChanged={(order) => this.onOrderChanged(order)}
          userCompensation={compensation}
          sendInLockerSMS={this.props.sendReadyInLockerSMS}
          sendDelaySMS={this.props.sendDelaySMS}
          checkForImages={this.props.checkForImages}
          InLockerDateExtend={() => this.inLockerDateExtend()}
          showOrderItemsOriginalModal={(isShowOriginalItemsModal) =>
            this.showOrderItemsOriginalModal(isShowOriginalItemsModal)
          }
          showOrderImagesModal={(isShowOrderImages) =>
            this.showOrderImagesModal(isShowOrderImages)
          }
          sendInLockerReturnSMS={this.props.sendInLockerReturnSMS}
          confirmResetDeliveryLoginCode={() =>
            this.confirmResetDeliveryLoginCode()
          }
          sendSMSForChangeStation={this.props.sendSMSForChangeStation}
        />

        <div className="order-editor-bottom-section">
          <div className="order-editor-bottom-right-part">
            <BlueButton
              extraClass={`order-editor-bottom-right-black-btn ${
                orderDetails.orderStatus == 18 || orderDetails.orderStatus == 19
                  ? "disabled"
                  : ""
              }`}
              onClick={(e) => this.printWhiteReceipt()}
              disabled={
                orderDetails.orderStatus == 18 || orderDetails.orderStatus == 19
              }
            >
              הדפסת העתק אישור הזמנה
            </BlueButton>

            <BlueButton
              extraClass="order-editor-bottom-right-black-btn"
              onClick={(e) => this.printPinkLabel()}
            >
              הדפסת תווית הידרופיקס
            </BlueButton>

            <div className="order-editor-comp-wrapper cus-det-frame-debt-wrapper">
              <CheckboxAndLabel
                //disabled={orderDetails.accountType != 1}
                disabled={orderDetails.orderType !== 1}
                checked={isAllowedCompensation}
                onChange={(e) =>
                  this.onCompensationStateUpdate(e.target.checked)
                }
              >
                מותר לפיצוי לקוח
              </CheckboxAndLabel>
            </div>
          </div>
          <div className="order-editor-bottom-left-part">
            <div className="order-editor-bottom-left-flex-wrapper">
              <div className="order-editor-bottom-left-inner-wrapper">
                <Title>סה"כ לפני הנחה:</Title>
                <Title bold extraClass="cus-det-frame-details-value">
                  {orderPriceBeforeDiscount} ₪
                </Title>
              </div>
              <div className="order-editor-bottom-left-inner-wrapper">
                {`הנחה ${
                  isDiscountTypePercent ? "באחוזים" : 'בשקלים ללא מע"מ'
                }:`}

                <Title bold extraClass="cus-det-frame-details-value">
                  {isDiscountTypePercent
                    ? discount + "%"
                    : "₪" + discount?.toFixed(2)}
                </Title>
              </div>

              <div className="order-editor-bottom-left-inner-wrapper">
                <Title>סה"כ אחרי הנחה:</Title>
                <Title bold extraClass="cus-det-frame-details-value">
                  {orderPriceAfterDiscount} ₪
                </Title>
              </div>
              <div className="order-editor-bottom-left-inner-wrapper">
                <Title>סה"כ מחיר סופי :</Title>
                <Title bold extraClass="cus-det-frame-details-value">
                  {parseFloat(orderTotalPrice).toFixed(2)} ₪
                </Title>
              </div>
            </div>
            <div className="order-editor-bottom-left-flex-wrapper">
              <CheckboxAndLabel
                checked={printLabelIsChecked}
                onChange={(e) =>
                  this.setState({ printLabelIsChecked: e.target.checked })
                }
              >
                הדפסת תווית
              </CheckboxAndLabel>
              <CheckboxAndLabel
                checked={printReceiptIsChecked}
                onChange={(e) =>
                  this.setState({ printReceiptIsChecked: e.target.checked })
                }
              >
                הדפסת הזמנה
              </CheckboxAndLabel>
            </div>

            <div className="order-editor-bottom-holder">
              {orderDetails.orderType == 1 ? (
                <div className="order-editor-bottom-left-flex-wrapper">
                  <div className="order-editor-bottom-left-btn-wrap">
                    <BlueButton
                      extraClass="order-editor-bottom-left-green-btn"
                      onClick={(e) => this.applyWithSms()}
                    >
                      {isUpdateOrderLoading
                        ? "מבצע את פעולת העדכון. אנא המתן"
                        : "אישור הזמנה ושליחת SMS"}
                    </BlueButton>
                  </div>
                </div>
              ) : null}
              <div className="order-editor-bottom-left-flex-wrapper">
                <div className="order-editor-bottom-left-btn-wrap">
                  <BlueButton
                    extraClass="order-editor-bottom-left-green-btn"
                    onClick={(e) => this.onSaveAndApplyClick()}
                  >
                    {isUpdateOrderLoading
                      ? "מבצע את פעולת העדכון. אנא המתן"
                      : "אישור הזמנה"}
                  </BlueButton>
                </div>
              </div>
            </div>
          </div>
        </div>

        <YesNoModal
          show={showCancelOrderModal}
          title={"ביטול הזמנה"}
          text={"פעולה זו תבטל את ההזמנה, האם אתה בטוח ברצונך להמשיך..?"}
          onYesClick={() => this.cancelCurrentOrder()}
          onNoClick={() => this.setState({ showCancelOrderModal: false })}
        />

        <YesNoModal
          show={showPaidAreYouSureModal}
          title={"שינוי סטטוס הזמנה לשולם"}
          text={
            "לאחר פעולה זו, סטטוס ההזמנה ישתנה ל'שולם', האם אתה בטוח ברצונך להמשיך?"
          }
          onYesClick={() => this.changeOrderPaymentStatusToPaid()}
          onNoClick={() => this.setState({ showPaidAreYouSureModal: false })}
        />

        <YesNoModal
          show={showStatusChangeAreYouSureModal}
          title={"שינוי סטטוס ההזמנה"}
          text={` לאחר פעולה זו, סטטוס ההזמנה ישתנה ל - ${this.statusNameFromObj(
            statusesObj,
            orderStatus
          )}, האם אתה בטוח?`}
          onYesClick={() => this.onStatusChangeAreYouSureModalOkClick()}
          onNoClick={() =>
            this.setState({ showStatusChangeAreYouSureModal: false })
          }
        />

        <YesNoModal
          show={showRemoveCouponAreYouSureModal}
          title={"הסרת הנחת קופון"}
          text={` לאחר פעולה זו, תוסר הנחת קופון מההזמנה זו , האם אתה בטוח?`}
          onYesClick={() => this.onAprooveRemoveCouponClick()}
          onNoClick={() =>
            this.setState({ showRemoveCouponAreYouSureModal: false })
          }
        />

        <YesNoModal
          show={showConfirmResetDeliveryLoginCodeModal}
          title={"איפוס קוד  ללוקר"}
          text={
            <div className="warning-text">
              לאחר פעולה זו קוד כניסה ללוקר ישתנה והלקוח לא יוכל לפתוח את הלוקר.
              האם אתה בטוח?
            </div>
          }
          onYesClick={() => this.onConfirmResetDeliveryCode()}
          onNoClick={() =>
            this.setState({ showConfirmResetDeliveryLoginCodeModal: false })
          }
        />
        <YesNoModal
          show={showReturnToSourceModal}
          title={"החל תהליך החזר חבילה לשולח"}
          text={
            <div className="warning-text">
              לאחר פעולה זו יופעל תהליך החזרת חבילה לשולח. האם אתה בטוח?
            </div>
          }
          onYesClick={() => this.onConfirmReturnToSource()}
          onNoClick={() => this.setState({ showReturnToSourceModal: false })}
        />

        <OrderNotesModal
          show={showCommentsOnOrderModal}
          title={`הערות עבור הזמנה מס' - ${orderDetails.orderNumber} `}
          notes={this.getOrderNotes(orderDetails)}
          onOkClick={() => this.setState({ showCommentsOnOrderModal: false })}
        />
        <NotificationModal
          show={showCommentsOnUserModal}
          title={`הערות עבור - ${firstName} ${lastName}`}
          text={comment}
          onOkClick={() => this.setState({ showCommentsOnUserModal: false })}
        />

        <NotificationModal
          show={showCantSetStatusModal}
          title={"שינוי סטטוס ההזמנה"}
          text={`לא ניתן לשנות סטטוס ל "מוכן בלוקר"`}
          onOkClick={() => this.setState({ showCantSetStatusModal: false })}
        />

        <NotificationModal
          show={showCantSetStatusModal2}
          title={"שינוי סטטוס ההזמנה"}
          text={` לא ניתן לבחור את הסטטוס  עבור הזמנה זו`}
          onOkClick={() => this.setState({ showCantSetStatusModal2: false })}
        />
        <NotificationModal
          show={showWillReturnToWarehouse}
          title={"שינוי סטטוס ההזמנה"}
          text="שים לב! אחרי שינוי ההזמנה תחזור למחסן."
          onOkClick={() => this.setState({ showWillReturnToWarehouse: false })}
        />

        <NotificationModal
          show={showOrderItemValidation}
          title={"הערות עבור הזמנה"}
          text={"חובה לבחור שם פריט"}
          onOkClick={() => this.setState({ showOrderItemValidation: false })}
        />

        <SelectCouponModal
          show={showAddCouponModal}
          orderType={orderDetails.orderType}
          userId={userId}
          onSelectCoupon={(coupon) => this.onSelectedCouponToAddToOrder(coupon)}
          onClose={() => this.onCloseCouponModal()}
        />

        <NotificationModal
          show={calculateError}
          title={"שינוי מחיר"}
          text={`לא ניתן לשנות את ההזמנה`}
          onOkClick={() => {
            this.props.getOrderDetails(this.props.orderDetails.id)
            this.props.clearCalculateError()
          }}
        />

        <NotificationModal
          show={showCheapSimNoUpdate}
          title="תהליך לא תקין"
          text="שינוי סטטוס הזמנה במקרה זה אמור להתבצע בתהליך אחר"
          onOkClick={() => this.setState({ showCheapSimNoUpdate: false })}
        />

        <NotificationModal
          show={showNoItemsMsg}
          type={"warning"}
          title="תהליך לא תקין"
          text="לא ניתן לשמור הזמנה ללא פריטים"
          onOkClick={() => this.setState({ showNoItemsMsg: false })}
        />

        <OrderInLockerExtendModal
          isOpen={this.state.showOrderInLockerExtendModal}
          isClose={() => this.setState({ showOrderInLockerExtendModal: false })}
          submitDate={(data) => this.updateInLockerDate(data)}
          inLockerDate={this.state.updatedOrder?.inLockerExtentionDate}
          isExtentionBilling={this.state.updatedOrder?.isExtentionBilling}
        />
        <NotificationModal
          title={"עדכון הזמנה"}
          show={this.props.orderInLockerExtendSuccess}
          text={"שינוים נשמרו בהצלחה, ההזמנה נדחתה."}
          onOkClick={() => {
            this.props.getOrderDetails(this.props.orderDetails.id)
            this.onCloseOrderInLockerExtendModal()
          }}
        />
        <NotificationModal
          title={"עדכון הזמנה"}
          type="error"
          show={this.props.orderInLockerExtendFail}
          text={"שגיא, הזמנה לא נדחתה "}
          onOkClick={() => this.onCloseOrderInLockerExtendModal()}
        />
        <NotificationModal
          show={this.props.deliveryLoginCodeResetSuccess}
          title={"איפוס קוד ללוקר"}
          text={"קוד כניסה ללוקר אופס בהצלחה"}
          onOkClick={() => this.clearAndRefreshOrder()}
        />
        <NotificationModal
          show={this.props.deliveryLoginCodeResetFail}
          title={"איפוס קוד ללוקר"}
          text={"שגיאה באיפוס קוד ללוקר"}
          onOkClick={() => this.clearAndRefreshOrder()}
        />
        <CreateOrderHistoryNoteModal
          show={showOrderHistoryNoteModal}
          isRequiredNote={true}
          updateOrderStatusNote={(e) => this.onFillHistoryNoteClick(e)}
        />

        <CreateOrderHistoryNoteModal
          show={showCancelOrderHistoryNoteModal}
          isRequiredNote={true}
          updateOrderStatusNote={(e) =>
            this.onCancelOrderFillHistoryNoteClick(e)
          }
        />

        <SelectStationChangeModal
          show={showStationChangeStateModal}
          order={updatedOrder}
          onSubmitClick={(orderId, targetStationId, isStationChangeBilling) =>
            this.setTargetStationChangeState(
              orderId,
              targetStationId,
              isStationChangeBilling
            )
          }
          onCancelClick={() =>
            this.setState({ showStationChangeStateModal: false })
          }
        />
        <SelectStationSourceChangeModal
          show={showStationSourceChangeStateModal}
          order={updatedOrder}
          onCloseClick={() =>
            this.setState({ showStationSourceChangeStateModal: false })
          }
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  ordersList,
  ordersStatuses,
  sendSmsSuccess,
  sendSmsFail,
  coupons,
  suppliers,
  selectOptions,
  stationsList,
}) => {
  const {
    ordersListArr,
    isUpdateOrderLoading,
    orderItemsOriginal,
    isRecalculated,
    calculateError,
    orderInLockerExtendSuccess,
    orderInLockerExtendFail,
    deliveryLoginCodeResetSuccess,
    deliveryLoginCodeResetFail,
    changeTargetStationSuccess,
  } = ordersList
  const { orderStatusesObj } = ordersStatuses
  const { coupon } = coupons
  const { suppliersList } = suppliers
  const { orderTypes, doneOrderTypes, accountTypes, orderStorageStatuses } =
    selectOptions
  const { sortedStationsNames } = stationsList

  return {
    ordersListArr,
    statusesObj: orderStatusesObj,
    isUpdateOrderLoading,
    sendSmsSuccess,
    sendSmsFail,
    coupon,
    suppliersList,
    orderTypes,
    doneOrderTypes,
    sortedStationsNames,
    orderItemsOriginal,
    isRecalculated,
    calculateError,
    accountTypes,
    orderInLockerExtendSuccess,
    orderInLockerExtendFail,
    deliveryLoginCodeResetSuccess,
    deliveryLoginCodeResetFail,
    changeTargetStationSuccess,
    orderStorageStatuses,
  }
}

export default connect(mapStateToProps, {
  updateOrderData,
  printPinkLabel,
  printHydroLabel,
  printWhiteReceipt,
  fetchAllOrders,
  getOrderDetails,
  updateUserCompensation,
  sendReadyInLockerSMS,
  setSelectedCoupon,
  fetchCouponById,
  setEmptyCoupon,
  addOrderCoupon,
  removeOrderCoupon,
  fetchActiveOrderUserCoupons,
  getAllSuppliers,
  fetchOrderTypes,
  fetchDoneOrderTypes,
  getOrderItemsOriginal,
  recalcOrderNoSave,
  clearRecalculated,
  clearCalculateError,
  sendDelaySMS,
  fetchAccountTypes,
  sendInLockerReturnSMS,
  sendSMSForChangeStation,
  updateOrderStatusAndExtentionDate,
  clearOrderInLockerExtend,
  resetDeliveryLoginCode,
  clearDeliveryLoginCodeReset,
  setTargetStationChangeState,
  onStartOrdersLoading,
  clearChangeTargetStation,
  startReturnToSender,
  getOrderStorageStatuses,
})(OrderEditor)
