import React from "react"
import { BlueButton } from "../../../common/BlueButton"
import "./CloseOrdersSack.scss"

const CloseOrdersSack = (props) => {
  return (
    <section className="closeOrdersSack">
      <div className="title">סגירת שק</div>
      <div>
        <BlueButton
          extraClass="btn-closeOrdersSack"
          onClick={() => props.history.push("/CloseOrdersSackPage")}
        >
          סגירת שק
        </BlueButton>
      </div>
    </section>
  )
}

export default CloseOrdersSack
