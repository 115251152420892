import {
  ON_GET_ROUTE_AREA_CODE_SUCCESS,
  ON_GET_ROUTE_AREA_CODE_FAIL,
  ON_CREATE_ROUTE_AREA_CODE_SUCCESS,
  ON_CREATE_ROUTE_AREA_CODE_FAIL,
  ON_EDIT_ROUTE_AREA_CODE_SUCCESS,
  ON_EDIT_ROUTE_AREA_CODE_FAIL,
  ON_SELECT_EDIT_ROUTE_AREA_CODE,
  CLEAR_AREA_ROUTE_CODE,
} from "../actions/types"
import { convertToSelectList } from "../utils/ConvertToSelectList"

const INIT_STATE = {
  routeAreaCodes: [],
  routeAreaCodesSelectList: [],
  routeAreaCodeArray: [],
  createAreaCodeSuccess: false,
  createAreaCodeFail: false,
  editAreaCodeSuccess: false,
  editAreaCodeFail: false,
  selectedRouteAreaCode: null,
}

const RouteAreaCodeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_GET_ROUTE_AREA_CODE_SUCCESS:
      let selectList = []
      let areaCodeArray = []
      if (action.payload) {
        selectList = action.payload.map((areaCode) => {
          return {
            value: areaCode.id,
            label:
              areaCode.routeAreaCodeName + " - " + areaCode.routeAreaCodeDesc,
          }
        })
        selectList = [
          {
            value: null,
            label: "כל הקודי אזור",
          },
          ...selectList,
        ]

        action.payload.map((areaCode) => {
          areaCodeArray[areaCode.id] = areaCode.routeAreaCodeName
        })
      }

      return {
        ...state,
        routeAreaCodes: action.payload,
        routeAreaCodesSelectList: selectList,
        routeAreaCodeArray: areaCodeArray,
      }
    case ON_GET_ROUTE_AREA_CODE_FAIL:
      return {
        ...state,
        routeAreaCodes: [],
      }
    case ON_CREATE_ROUTE_AREA_CODE_SUCCESS:
      return {
        ...state,
        createAreaCodeSuccess: true,
        createAreaCodeFail: false,
      }
    case ON_CREATE_ROUTE_AREA_CODE_FAIL:
      return {
        ...state,
        createAreaCodeSuccess: false,
        createAreaCodeFail: true,
      }
    case ON_EDIT_ROUTE_AREA_CODE_SUCCESS:
      return {
        ...state,
        editAreaCodeSuccess: true,
        editAreaCodeFail: false,
      }
    case ON_EDIT_ROUTE_AREA_CODE_FAIL:
      return {
        ...state,
        editAreaCodeSuccess: false,
        editAreaCodeFail: true,
      }
    case ON_SELECT_EDIT_ROUTE_AREA_CODE:
      return {
        ...state,
        selectedRouteAreaCode: action.payload,
      }
    case CLEAR_AREA_ROUTE_CODE:
      return {
        ...state,
        createAreaCodeSuccess: false,
        createAreaCodeFail: false,
        editAreaCodeSuccess: false,
        editAreaCodeFail: false,
        selectedRouteAreaCode: null,
      }
    default:
      return state
  }
}

export default RouteAreaCodeReducer
