import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import store from "../../../store"

import "./style.css"

const Modal = ({ children, extraClass }) => {
  const [modalContainer] = useState(() => document.createElement("div"))

  useEffect(() => {
    modalContainer.className = `modal ${extraClass || ""}`
    document.body.appendChild(modalContainer)

    return () => {
      document.body.removeChild(modalContainer)
    }
  }, [modalContainer, extraClass])

  return ReactDOM.createPortal(
    <Provider store={store}>
      <div>{children}</div>
    </Provider>,
    modalContainer
  )
}

export default Modal
