import React, { Component } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import {
  readLockersLayout,
  createStation,
  editStation,
  editLockers,
  cleanStationForm,
  closeNotificationSuccessed,
  closeNotificationFailture,
  showStationLoading,
  fetchLockerTypeSelectOptions,
  fetchRoutes,
  fetchCities,
  fetchStations,
  getPostalCode,
  closeNotificationPostalCodeError,
  closeNotificationPostalCodeInvalid,
  fetchLockerSizes,
  fetchAllOrderStatuses,
  isAreaCodeCanBeLinkedToStation,
  getStationById,
  clearAreaCodeCanBeLinked,
  getAllRouteAreaCodes,
} from "../../../../actions"
import { fetchIfEmpty } from "../../../../utils"
import LockersList from "../LockersList"
import {
  LabeledTextInput,
  LabeledSelect,
  CheckboxAndLabel,
  LabeledFileInput,
  LabeledTextarea,
  Spinner,
} from "../../../common"
import NotificationModal from "../../../Modals/NotificationModal"
import "./style.scss"

class NewStation extends Component {
  constructor(props) {
    super(props)
    this.handleNotificationSuccessClick =
      this.handleNotificationSuccessClick.bind(this)
    this.handleNotificationFailtureClick =
      this.handleNotificationFailtureClick.bind(this)
    this.handlePostalCodeErrorClick = this.handlePostalCodeErrorClick.bind(this)
    this.handlePostalCodeInvalidClick =
      this.handlePostalCodeInvalidClick.bind(this)

    this.INIT_STATE = {
      showReturnPackageError: false,
      selectedRouteId: null,
      formatedRouteAreacCodes: [],
      showAreaCodeCanBeLinkedMsg: false,
    }

    this.state = this.INIT_STATE
  }

  componentWillMount() {
    const { unauthorized, history } = this.props
    if (unauthorized) {
      history.push("/")
    }
  }

  componentDidMount() {
    const { station, routesList, citiesList, lockersSizes } = this.props
    this.props.cleanStationForm()
    fetchIfEmpty(
      this.props.lockerTypes,
      this.props.fetchLockerTypeSelectOptions
    )
    fetchIfEmpty(routesList, this.props.fetchRoutes)
    fetchIfEmpty(citiesList, this.props.fetchCities)
    fetchIfEmpty(lockersSizes, this.props.fetchLockerSizes)
    this.props.getAllRouteAreaCodes()
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps) {
    const { unauthorized, history, station } = this.props

    if (unauthorized && !prevProps.unauthorized) {
      history.push("/")
    }

    if (station !== prevProps.station) {
      this.setRouteAreaCodesByRouteId(station.routeId)
      this.setState({ showAreaCodeCanBeLinkedMsg: false })
      this.props.clearAreaCodeCanBeLinked()
    }
  }

  paymentVersionList = [
    { value: 1, label: "דור ראשון" },
    { value: 2, label: "דור שני" },
    { value: 3, label: "Nayax" },
    { value: 4, label: "אפליקציית מובייל" },
    { value: 5, label: "Hybrid" },
    { value: 6, label: "Pudo" },
  ]

  setRouteAreaCodesByRouteId(routeId) {
    const { routeAreaCodes } = this.props

    const filteredObjects = routeAreaCodes.filter(
      (obj) => obj.routeId === routeId
    )
    const formatedRouteAreacCodes =
      this.formatRouteAreaCodesObject(filteredObjects)

    this.setState({ formatedRouteAreacCodes })
  }

  handleStationChange(inputName, inputValue) {
    const {
      station,
      isAreaCodeCanBeLinkedToStation,
      readLockersLayout,
      editStation,
    } = this.props

    let _station = { ...station, [inputName]: inputValue }

    switch (inputName) {
      case "routeAreaCodeId":
        isAreaCodeCanBeLinkedToStation(inputValue)
        break

      case "routeId":
        this.setState({ selectedRouteId: inputValue })
        this.setRouteAreaCodesByRouteId(inputValue)
        break

      case "isLaundryActive":
        const isLaundryActive = inputValue === "true" || inputValue === true
        _station.minimumLaundryEmptyLockers = isLaundryActive
          ? _station.minimumLaundryEmptyLockers || 2
          : 0
        break

      case "isDecathlon":
      case "isAmshat":
      case "isYadMordechai":
      case "isBerorHayil":
      case "isSdeMoshe":
      case "isAmirim":
      case "isApple":
      case "isTAU":
      case "isMaayanZvi":
        _station = this.resetStationFlags(inputName, _station)
        break

      case "layoutFile":
        readLockersLayout(inputValue)
        break

      default:
        break
    }

    _station = this.validateFields(_station)
    editStation(_station)
  }

  resetStationFlags(activeFlag, station) {
    const flags = [
      "isDecathlon",
      "isAmshat",
      "isYadMordechai",
      "isBerorHayil",
      "isSdeMoshe",
      "isAmirim",
      "isApple",
      "isTAU",
      "isMaayanZvi",
    ]

    flags.forEach((flag) => {
      station[flag] = flag === activeFlag
    })

    return station
  }

  validateFields(_station) {
    let { minimumLaundryEmptyLockers, stationPriority, latitude, longitude } =
      _station

    _station.minimumLaundryEmptyLockers =
      minimumLaundryEmptyLockers === "" ? 0 : minimumLaundryEmptyLockers
    _station.stationPriority =
      !stationPriority || stationPriority === "" ? 0 : stationPriority
    _station.latitude = !latitude || latitude === "" ? 0 : latitude
    _station.longitude = !longitude || longitude === "" ? 0 : longitude

    return _station
  }

  handleLockersChange(editedLocker, lockerIndex) {
    const { stationLockers } = this.props
    let _lockers = stationLockers.map((locker, index) =>
      index === lockerIndex ? editedLocker : locker
    )
    this.props.editLockers(_lockers)
  }

  isReturnPackagesValid(station) {
    if (station.isReturnPackageActive) {
      if (
        station.postalCode == "" ||
        station.postalCode == null ||
        station.cityId == "" ||
        station.cityId == null ||
        station.branchName_en == "" ||
        station.branchName_en == null ||
        station.branchAddress_en == "" ||
        station.branchAddress_en == null ||
        station.routeAreaCodeId === "" ||
        station.routeAreaCodeId === null
      ) {
        this.setState({ showReturnPackageError: true })
        return false
      }
    }
    return true
  }

  onSubmitButtonClick(station, layoutFile, event) {
    event.preventDefault()
    if (this.props.isAreaCodeCanBeLinked == false) {
      this.setState({ showAreaCodeCanBeLinkedMsg: true })
      return
    }
    let isReturnValid = this.isReturnPackagesValid(station)
    if (!isReturnValid) {
      return
    }
    this.props.showStationLoading()
    station = this.validateFields(station)
    this.props.createStation(station, layoutFile)
  }

  handleNotificationSuccessClick() {
    this.props.fetchStations()

    this.props.closeNotificationSuccessed()
    this.props.history.goBack()
  }

  handleNotificationFailtureClick() {
    this.props.closeNotificationFailture()
  }

  handlePostalCodeErrorClick() {
    this.props.closeNotificationPostalCodeError()
  }

  handlePostalCodeInvalidClick() {
    this.props.closeNotificationPostalCodeInvalid()
  }
  handleIsAreaCodeCanBeLinked() {
    this.setState({ showAreaCodeCanBeLinkedMsg: false })
  }

  handleOpenCoordinatesClick(latitude, longitude, event) {
    event.preventDefault()
    window.open(
      `https://www.google.com/maps/place/${latitude},${longitude}`,
      "_blank"
    )
  }

  getPostalCodeFromCoordinates(latitude, longitude, event) {
    event.preventDefault()
    this.props.getPostalCode(latitude, longitude)
  }

  openVideoUrl(videoUrl, event) {
    event.preventDefault()
    window.open(videoUrl, "_blank")
  }

  formatRouteAreaCodesObject(arrayOfObjects) {
    return arrayOfObjects.map((obj) => ({
      label:
        obj.routeAreaCodeName +
        (obj.routeAreaCodeDesc ? " - " + obj.routeAreaCodeDesc : ""),
      value: obj.id,
      routeId: obj.routeId,
    }))
  }

  render() {
    const {
      priceLists,
      showNotification_Success,
      showNotification_Faiture,
      showPostalCodeError,
      showPostalCodeInvalid,
      isLoading,
      routeAreaCodes,
    } = this.props
    const {
      showReturnPackageError,
      selectedRouteId,
      formatedRouteAreacCodes,
      showAreaCodeCanBeLinkedMsg,
    } = this.state

    const {
      stationNumber,
      branchName,
      branchAddress,
      branchName_en,
      branchAddress_en,
      branchDisplayAddress,
      branchDisplayName,
      routeId,
      cityId,
      barStationNumber,
      isAlive,
      isDeliveryActive,
      isLaundryActive,
      isLockerRentActive,
      isLockerToLockerDeliveryActive,
      isDecathlon,
      minimumLaundryEmptyLockers,
      priceListId,
      layoutFile,
      latitude,
      longitude,
      availabilityHours,
      isDeliveryCompaniesActive,
      stationPaymentVersion,
      stationPriority,
      isAmot,
      isBLE,
      isYadMordechai,
      isSdeMoshe,
      isAmirim,
      isOutdoor,
      isVisible,
      nayaxTerminalSN,
      postalCode,
      isReturnPackageActive,
      videoUrl,
      isReturnExelotActive,
      isApple,
      dhlStationNumber,
      dhlRouteCode,
      isTAU,
      routeAreaCodeId,
      isBerorHayil,
      nearByStationId,
      nearByStationId2,
      nearByStationId3,
      isAmshat,
      isMaayanZvi,
    } = this.props.station
    const { stationLockers } = this.props
    const route =
      routeId && routeId != ""
        ? this.props.routesList.filter((r) => r.value === routeId)[0]
        : { value: null, label: "" }
    const city =
      cityId && cityId != ""
        ? this.props.citiesList.filter((r) => r.value === cityId)[0]
        : { value: null, label: "" }

    const nearByStation =
      nearByStationId && nearByStationId !== ""
        ? this.props.stationsItemSelectList.filter(
            (r) => r.stationId === nearByStationId
          )[0]
        : { value: null, label: "" }
    const nearByStation2 =
      nearByStationId2 && nearByStationId2 !== ""
        ? this.props.stationsItemSelectList.filter(
            (r) => r.stationId === nearByStationId2
          )[0]
        : { value: null, label: "" }
    const nearByStation3 =
      nearByStationId3 && nearByStationId3 !== ""
        ? this.props.stationsItemSelectList.filter(
            (r) => r.stationId === nearByStationId3
          )[0]
        : { value: null, label: "" }

    console.log(">>> New station input:", this.props.station)
    console.log(">>> New station input:", stationLockers)

    if (isLoading) {
      return (
        <div className="station-editor-spinner-wrapper">
          <Spinner zoom={2} />
        </div>
      )
    }

    if (showNotification_Success) {
      return (
        <NotificationModal
          show={showNotification_Success}
          title={" הוספת עמדה חדשה "}
          text={"העמדה הוספה בהצלחה"}
          onOkClick={this.handleNotificationSuccessClick}
        />
      )
    }
    if (showNotification_Faiture) {
      return (
        <NotificationModal
          show={showNotification_Faiture}
          title={" הוספת עמדה חדשה "}
          text={"תהליך הוספת עמדה חדשה נכשל"}
          onOkClick={this.handleNotificationFailtureClick}
        />
      )
    }
    if (showPostalCodeError) {
      return (
        <NotificationModal
          show={showPostalCodeError}
          title={"יצירת מיקוד"}
          text={"כישלון ביצירת מיקוד"}
          onOkClick={this.handlePostalCodeErrorClick}
        />
      )
    }
    if (showPostalCodeInvalid) {
      return (
        <NotificationModal
          show={showPostalCodeInvalid}
          title={"יצירת מיקוד"}
          text={"מיקוד שקיבלת לא חוקי, אנא חפש ידני."}
          onOkClick={this.handlePostalCodeInvalidClick}
        />
      )
    }
    if (showReturnPackageError) {
      return (
        <NotificationModal
          show={showReturnPackageError}
          type={"warning"}
          title={"תהליך הוספת עמדה חדשה נכשל"}
          text={
            "חובה להכניס מיקוד, עיר, קוד איזור ,שם סניף באנגלית וכתובת סניף באנגלית לעמדות שתומכות בהחזרה"
          }
          onOkClick={() =>
            this.setState({
              showReturnPackageError: false,
            })
          }
        />
      )
    }
    if (showAreaCodeCanBeLinkedMsg == true) {
      return (
        <NotificationModal
          show={showAreaCodeCanBeLinkedMsg}
          type={"warning"}
          title={" מגבלה של קודי איזור עבור תחנה זו"}
          text={"אין אפשרות לקשר יותר קודי איזור לתחנה"}
          onOkClick={() => this.handleIsAreaCodeCanBeLinked()}
        />
      )
    }
    return (
      <div className="station-new">
        <h1>יצירת עמדה חדשה</h1>

        <form className="ss-container">
          <div className="stations-section-station-info">
            <LabeledTextInput
              labelText={"מס' עמדה"}
              value={stationNumber}
              inputSize={6}
              onChange={(e) => {
                this.handleStationChange("stationNumber", e.target.value)
              }}
            />
            <LabeledTextInput
              labelText={"שם סניף"}
              value={branchName}
              inputSize={30}
              onChange={(e) => {
                this.handleStationChange("branchName", e.target.value)
              }}
            />
            <LabeledTextInput
              labelText={"כתובת סניף"}
              value={branchAddress}
              inputSize={50}
              onChange={(e) => {
                this.handleStationChange("branchAddress", e.target.value)
              }}
            />
          </div>
          <div className="address-eng">
            <LabeledTextInput
              labelText={"שם סניף באנגלית (עם עיר בסוגריים)"}
              value={branchName_en}
              inputSize={30}
              onChange={(e) => {
                this.handleStationChange("branchName_en", e.target.value)
              }}
            />
            <LabeledTextInput
              labelText={"כתובת סניף באנגלית (כתובת ללא עיר)"}
              value={branchAddress_en}
              inputSize={50}
              maxlength={45}
              onChange={(e) => {
                this.handleStationChange("branchAddress_en", e.target.value)
              }}
            />
          </div>
          <div className="stations-section-station-info-row">
            <LabeledTextInput
              labelText={
                "שם עמדה לסמס (שם העמדה שישלח בסמס. אם אין ערך בשדה ישלח שם הסניף) (עם עיר בסוגריים)"
              }
              value={branchDisplayName}
              inputSize={100}
              onChange={(e) => {
                this.handleStationChange("branchDisplayName", e.target.value)
              }}
            />
          </div>
          <div className="stations-section-station-info-row">
            <LabeledTextInput
              labelText={
                "כתובת לסמס  (הכתובת שתשלח בסמס, אם אין ערך בשדה לא תשלח כתובת סמס) (כתובת ללא עיר)"
              }
              value={branchDisplayAddress}
              inputSize={100}
              onChange={(e) => {
                this.handleStationChange("branchDisplayAddress", e.target.value)
              }}
            />
          </div>
          <div className="stations-section-station-info-row">
            <div className="container-select route-select">
              <div className="route-label">{"מסלול הפצה"}</div>
              <Select
                classNamePrefix="base-select"
                placeholder={"מסלול הפצה"}
                value={route}
                options={this.props.routesList}
                onChange={(e) => this.handleStationChange("routeId", e.value)}
              />
            </div>
          </div>
          <div className="stations-section-station-info-row">
            <div className="container-select route-select">
              <div className="route-label ss-label">{"קוד אזור מסלול"}</div>
              <Select
                key={selectedRouteId}
                classNamePrefix="base-select"
                options={formatedRouteAreacCodes}
                onChange={(e) =>
                  this.handleStationChange("routeAreaCodeId", e.value)
                }
              />
              {!this.props.isAreaCodeCanBeLinked ? (
                <div className="area-code-limit-msg">
                  יש יותר מי 20 תחנות קשורות לקוד איזור זה.
                </div>
              ) : null}
            </div>
          </div>
          <div className="stations-section-station-info-row">
            <div className="container-select city-select">
              <div className="route-label ss-label">עיר</div>
              <Select
                classNamePrefix="base-select"
                placeholder={"עיר"}
                value={city}
                options={this.props.citiesList}
                onChange={(e) => this.handleStationChange("cityId", e.value)}
              />
            </div>
          </div>
          <div className="stations-section-station-info-row">
            <LabeledTextInput
              labelText={"מספר עמדה בבר הפצה"}
              value={barStationNumber}
              inputSize={12}
              onChange={(e) => {
                this.handleStationChange("barStationNumber", e.target.value)
              }}
            />
          </div>

          <div className="stations-section-station-info-row">
            <LabeledTextInput
              labelText={"קוד תחנה בDHL"}
              value={dhlStationNumber}
              inputSize={12}
              onChange={(e) => {
                this.handleStationChange("dhlStationNumber", e.target.value)
              }}
            />
          </div>

          <div className="stations-section-station-info-row">
            <LabeledTextInput
              labelText={"מספר קו בDHL"}
              value={dhlRouteCode}
              inputSize={12}
              onChange={(e) => {
                this.handleStationChange("dhlRouteCode", e.target.value)
              }}
            />
          </div>

          <div className="checkbox-holder">
            <div className="chebox-container">
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={isDeliveryActive}
                  onChange={(e) => {
                    this.handleStationChange(
                      "isDeliveryActive",
                      e.target.checked
                    )
                  }}
                >
                  {"האם העמדה זמינה לקבלת חבילות?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={isLaundryActive}
                  onChange={(e) => {
                    this.handleStationChange(
                      "isLaundryActive",
                      e.target.checked
                    )
                  }}
                >
                  {"האם העמדה זמינה לשרותי כביסה?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={isLockerRentActive}
                  onChange={(e) => {
                    this.handleStationChange(
                      "isLockerRentActive",
                      e.target.checked
                    )
                  }}
                >
                  {"האם העמדה זמינה לשרותי השכרת תא?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={isLockerToLockerDeliveryActive}
                  onChange={(e) => {
                    this.handleStationChange(
                      "isLockerToLockerDeliveryActive",
                      e.target.checked
                    )
                  }}
                >
                  {"האם העמדה זמינה לשרותי משלוח בין תאים?"}
                </CheckboxAndLabel>
              </div>
              <CheckboxAndLabel
                checked={isDeliveryCompaniesActive}
                onChange={(e) => {
                  this.handleStationChange(
                    "isDeliveryCompaniesActive",
                    e.target.checked
                  )
                }}
              >
                {"האם העמדה פתוחה לחברות השילוח?"}
              </CheckboxAndLabel>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={isAmot}
                  onChange={(e) => {
                    this.handleStationChange("isAmot", e.target.checked)
                  }}
                >
                  {"האם העמדה נמצאת בבניין אמות Amot?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={isBLE}
                  onChange={(e) => {
                    this.handleStationChange("isBLE", e.target.checked)
                  }}
                >
                  {"האם העמדה BLE?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={isOutdoor}
                  onChange={(e) => {
                    this.handleStationChange("isOutdoor", e.target.checked)
                  }}
                >
                  {"האם העמדה נמצאת בחוץ?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={isVisible}
                  onChange={(e) => {
                    this.handleStationChange("isVisible", e.target.checked)
                  }}
                >
                  {"האם להציג באתר?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={isReturnPackageActive}
                  onChange={(e) => {
                    this.handleStationChange(
                      "isReturnPackageActive",
                      e.target.checked
                    )
                  }}
                >
                  {"האם העמדה זמינה להחזרות של בלדרים חיצוניים?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={isReturnExelotActive}
                  onChange={(e) => {
                    this.handleStationChange(
                      "isReturnExelotActive",
                      e.target.checked
                    )
                  }}
                >
                  {"האם העמדה זמינה להחזרות של נהגים שלנו?"}
                </CheckboxAndLabel>
              </div>
            </div>
            <div className="chebox-container">
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={isDecathlon}
                  onChange={(e) => {
                    this.handleStationChange("isDecathlon", e.target.checked)
                  }}
                >
                  {"האם העמדה שייכת לדקטלון?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={isYadMordechai}
                  onChange={(e) => {
                    this.handleStationChange("isYadMordechai", e.target.checked)
                  }}
                >
                  {"האם העמדה נמצאת ביד מורדכי?"}
                </CheckboxAndLabel>
              </div>

              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={isTAU}
                  onChange={(e) => {
                    this.handleStationChange("isTAU", e.target.checked)
                  }}
                >
                  {"האם העמדה נמצאת באוניברסיטת תל-אביב?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={isSdeMoshe}
                  onChange={(e) => {
                    this.handleStationChange("isSdeMoshe", e.target.checked)
                  }}
                >
                  {"האם העמדה נמצאת בשדה משה?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={isAmirim}
                  onChange={(e) => {
                    this.handleStationChange("isAmirim", e.target.checked)
                  }}
                >
                  {"האם העמדה נמצאת באמירים?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={isApple}
                  onChange={(e) => {
                    this.handleStationChange("isApple", e.target.checked)
                  }}
                >
                  {"האם העמדה נמצאת ב-Apple?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={isBerorHayil}
                  onChange={(e) => {
                    this.handleStationChange("isBerorHayil", e.target.checked)
                  }}
                >
                  {"האם העמדה נמצאת בברור חיל?"}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={isAmshat}
                  onChange={(e) => {
                    this.handleStationChange("isAmshat", e.target.checked)
                  }}
                >
                  {`האם העמדה שייכת לאמש"ט?`}
                </CheckboxAndLabel>
              </div>
              <div className="stations-section-station-info-row">
                <CheckboxAndLabel
                  checked={isMaayanZvi}
                  onChange={(e) => {
                    this.handleStationChange("isMaayanZvi", e.target.checked)
                  }}
                >
                  {"האם העמדה נמצאת  במעיין צבי?"}
                </CheckboxAndLabel>
              </div>
            </div>
          </div>
          <div className="alt-stations-section">
            <div className="container-select">
              <div className="route-label ss-label">עמדה חילופית 1</div>
              <Select
                classNamePrefix="base-select"
                placeholder="בחר עמדה חילופית 1"
                value={nearByStation}
                options={this.props.stationsItemSelectList}
                onChange={(e) =>
                  this.handleStationChange("nearByStationId", e.stationId)
                }
              />
            </div>
            <div className="container-select">
              <div className="route-label ss-label">עמדה חילופית 2</div>
              <Select
                classNamePrefix="base-select"
                placeholder="בחר עמדה חילופית 2"
                value={nearByStation2}
                options={this.props.stationsItemSelectList}
                onChange={(e) =>
                  this.handleStationChange("nearByStationId2", e.stationId)
                }
              />
            </div>
            <div className="container-select">
              <div className="route-label ss-label">עמדה חילופית 3</div>
              <Select
                classNamePrefix="base-select"
                placeholder="בחר עמדה חילופית 3"
                value={nearByStation3}
                options={this.props.stationsItemSelectList}
                onChange={(e) =>
                  this.handleStationChange("nearByStationId3", e.stationId)
                }
              />
            </div>
          </div>
          <div className="stations-section-station-info-row">
            <LabeledTextInput
              labelText={"כמות מינימלית של תאים פנויים עבור שירותי כביסה"}
              value={minimumLaundryEmptyLockers}
              type={"number"}
              maxsize="12"
              inputSize={12}
              onChange={(e) => {
                this.handleStationChange(
                  "minimumLaundryEmptyLockers",
                  e.target.value
                )
              }}
            />
          </div>

          <LabeledSelect
            labelText={"מחירון"}
            options={priceLists}
            value={priceListId}
            onChange={(e) => {
              this.handleStationChange("priceListId", e.target.value)
            }}
          />
          <LabeledFileInput
            inputId="layout-file"
            labelText={"קובץ"}
            onChange={(e) => {
              this.handleStationChange("layoutFile", e.target.files[0])
            }}
          />
          <LockersList
            lockers={stationLockers}
            onLockersChange={this.handleLockersChange.bind(this)}
          />
          <div className="stations-section-station-info">
            <LabeledTextInput
              labelText={"longitude"}
              value={longitude}
              type="number"
              maxsize="12"
              pattern="^[-,0-9]+${1-12}"
              onChange={(e) => {
                this.handleStationChange("longitude", e.target.value)
              }}
            />
            <span className="comma-separator">,</span>
            <LabeledTextInput
              labelText={"latitude"}
              value={latitude}
              type="number"
              maxsize="12"
              pattern="^[-,0-9]+${1-12}"
              onChange={(e) => {
                this.handleStationChange("latitude", e.target.value)
              }}
            />
            <button
              className="ss-button"
              onClick={(e) =>
                this.handleOpenCoordinatesClick(latitude, longitude, e)
              }
            >
              {"פתח במפה"}
            </button>
          </div>

          <div className="stations-section-station-info-row">
            <LabeledTextInput
              labelText={"מיקוד"}
              value={postalCode}
              type={"text"}
              pattern="\d*"
              maxLength="16"
              onChange={(e) => {
                this.handleStationChange("postalCode", e.target.value)
              }}
            />
            <button
              className="ss-button"
              onClick={(e) =>
                this.getPostalCodeFromCoordinates(latitude, longitude, e)
              }
            >
              חשב מיקוד
            </button>
          </div>

          <div className="video-url">
            <LabeledTextarea
              labelText={"סרטון כיצד למצוא את התחנה"}
              value={videoUrl}
              inputSize={300}
              rows="3"
              onChange={(e) => {
                this.handleStationChange("videoUrl", e.target.value)
              }}
            />
            <button
              className="ss-button"
              onClick={(e) => this.openVideoUrl(videoUrl, e)}
            >
              פתח קישור
            </button>
          </div>

          <div className="textarea-container">
            <LabeledTextarea
              labelText={"שעות זמינות"}
              value={availabilityHours}
              inputSize={300}
              rows="3"
              onChange={(e) => {
                this.handleStationChange("availabilityHours", e.target.value)
              }}
            />
          </div>
          <div key={stationPaymentVersion} className="payment-version-wrapper">
            <div className="payment-version-label">{"גרסת תשלום"} </div>
            <Select
              defaultValue={
                stationPaymentVersion
                  ? this.paymentVersionList.find(
                      (o) => o.value === stationPaymentVersion
                    )
                  : { value: 2, label: "version 2" }
              }
              options={this.paymentVersionList}
              onChange={(e) => {
                this.handleStationChange("stationPaymentVersion", e.value)
              }}
            />
          </div>
          <div className="stations-section-station-info-row">
            <LabeledTextInput
              labelText={"מספר סידורי של Nayax Terminal"}
              value={nayaxTerminalSN}
              type={"text"}
              pattern="\d*"
              maxLength="16"
              onChange={(e) => {
                this.handleStationChange("nayaxTerminalSN", e.target.value)
              }}
            />
          </div>
          <div className="container-select">
            <LabeledSelect
              emptyOption={false}
              labelText={"עדיפות עמדה"}
              options={Array.from({ length: 21 }, (_, i) => i)}
              value={stationPriority}
              onChange={(e) => {
                this.handleStationChange("stationPriority", e.target.value)
              }}
            />
          </div>
          <button
            type="submit"
            className="ss-button"
            onClick={(e) => {
              this.onSubmitButtonClick(
                {
                  stationNumber,
                  branchName,
                  branchAddress,
                  branchName_en,
                  branchAddress_en,
                  branchDisplayAddress,
                  branchDisplayName,
                  barStationNumber,
                  isDeliveryActive,
                  isLaundryActive,
                  isLockerRentActive,
                  isLockerToLockerDeliveryActive,
                  minimumLaundryEmptyLockers,
                  priceListId,
                  stationLockers,
                  latitude,
                  longitude,
                  availabilityHours,
                  isDeliveryCompaniesActive,
                  stationPriority,
                  stationPaymentVersion,
                  isDecathlon,
                  routeId,
                  cityId,
                  isAmot,
                  isBLE,
                  isYadMordechai,
                  isSdeMoshe,
                  isAmirim,
                  isOutdoor,
                  isVisible,
                  nayaxTerminalSN,
                  postalCode,
                  isReturnPackageActive,
                  videoUrl,
                  dhlStationNumber,
                  dhlRouteCode,
                  isApple,
                  isTAU,
                  routeAreaCodeId,
                  isBerorHayil,
                  nearByStationId,
                  nearByStationId2,
                  nearByStationId3,
                  isAmshat,
                  isMaayanZvi,
                },
                layoutFile,
                e
              )
            }}
          >
            {"שמור"}
          </button>
        </form>
      </div>
    )
  }
}

const MapStateToProps = ({
  stationsSection,
  selectOptions,
  errors,
  routes,
  cities,
  routeAreaCode,
  stationsList,
}) => {
  const {
    station,
    stationLockers,
    showNotification_Success,
    showNotification_Faiture,
    isLoading,
    showPostalCodeError,
    showPostalCodeInvalid,
  } = stationsSection
  const { isAreaCodeCanBeLinked, stationsItemSelectList } = stationsList
  const { priceLists, lockersSizes } = selectOptions
  const { unauthorized } = errors
  const routesList = routes.routes
  const { citiesList } = cities
  const { routeAreaCodes } = routeAreaCode

  return {
    priceLists,
    station,
    stationLockers,
    showNotification_Success,
    showNotification_Faiture,
    showPostalCodeError,
    isLoading,
    unauthorized,
    routesList,
    citiesList,
    showPostalCodeError,
    showPostalCodeInvalid,
    lockersSizes,
    routeAreaCodes,
    isAreaCodeCanBeLinked,
    stationsItemSelectList,
  }
}

export default connect(MapStateToProps, {
  readLockersLayout,
  createStation,
  editStation,
  editLockers,
  cleanStationForm,
  closeNotificationSuccessed,
  closeNotificationFailture,
  showStationLoading,
  fetchLockerTypeSelectOptions,
  fetchRoutes,
  fetchCities,
  getPostalCode,
  fetchStations,
  closeNotificationPostalCodeError,
  closeNotificationPostalCodeInvalid,
  fetchLockerSizes,
  getStationById,
  isAreaCodeCanBeLinkedToStation,
  clearAreaCodeCanBeLinked,
  getAllRouteAreaCodes,
})(NewStation)
