import React from "react"
import { BlueButton } from "../../../common/BlueButton"
import "./SearchStorageSack.scss"

const SearchStorageSack = (props) => {
  return (
    <section className="searchStorageSack">
      <div className="title">חיפוש שקי מחסן</div>
      <div>
        <BlueButton
          extraClass="btn-closeStorageSack"
          onClick={() => props.history.push("/SearchStorageSackPage")}
        >
          חיפוש שקי מחסן
        </BlueButton>
      </div>
    </section>
  )
}

export default SearchStorageSack
