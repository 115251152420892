import React from "react"
import { connect } from "react-redux"
import "./style.scss"
import SackScanner from "../ScanOrder/ScanOrder"

const ScanOrderMobilePage = (props) => {
  return (
    <section className="scanOrderMobilePage">
      <SackScanner history={props.history} />
    </section>
  )
}

const mapStateToProps = ({ ordersList }) => {
  const {} = ordersList
  return {}
}

export default connect(mapStateToProps, {})(ScanOrderMobilePage)
