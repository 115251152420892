export const stations_RecalcFilteredListOutOfLatestState = (state) => {
  const { stations, stationsLocalSearch } = state

  const {
    searchText,
    filtererActiveStations,
    searchPaymentVersion,
    isAliveStatus,
    isVisibleStatus,
    routeId,
    areaCodeId,
  } = stationsLocalSearch

  const selectedStationNumbers = filtererActiveStations.map(
    ({ value }) => value
  )
  let stationsFilteredListArr = stations.filter(
    ({ stationNumber }) =>
      selectedStationNumbers.includes(stationNumber) ||
      selectedStationNumbers.length === 0
  )

  if (searchText && searchText.length > 0) {
    stationsFilteredListArr = stations_FilterBySearchText(
      stationsFilteredListArr,
      searchText
    )
  }
  if (searchPaymentVersion && selectedStationNumbers.length == 0) {
    stationsFilteredListArr = filterByPaymentVersion(
      stationsFilteredListArr,
      searchPaymentVersion
    )
  }

  if (isAliveStatus && selectedStationNumbers.length == 0) {
    stationsFilteredListArr = filterByIsAlive(
      stationsFilteredListArr,
      isAliveStatus
    )
  }

  if (isVisibleStatus && selectedStationNumbers.length == 0) {
    stationsFilteredListArr = filterByIsVisible(
      stationsFilteredListArr,
      isVisibleStatus
    )
  }

  if (routeId && selectedStationNumbers.length == 0) {
    stationsFilteredListArr = filterByRouteId(stationsFilteredListArr, routeId)
  }

  if (areaCodeId && selectedStationNumbers.length == 0) {
    stationsFilteredListArr = filterByAreaCodeId(
      stationsFilteredListArr,
      areaCodeId
    )
  }

  return {
    ...state,
    stationsLocalSearch: {
      ...stationsLocalSearch,
      stationsFilteredListArr,
    },
  }
}

export const filterByPaymentVersion = (list, version) => {
  if (version == 0) {
    return stationsFilteredListArr
  }
  const stationsFilteredListArr = list.filter((station) => {
    const { stationPaymentVersion } = station
    return stationPaymentVersion.toString() == version
  })

  return stationsFilteredListArr
}

export const filterByIsAlive = (list, aliveStatus) => {
  if (aliveStatus == 0) {
    return stationsFilteredListArr
  }
  const isAliveBoolean = aliveStatus == 1 ? true : false
  const stationsFilteredListArr = list.filter((station) => {
    const { isAlive } = station
    return isAlive == isAliveBoolean
  })

  return stationsFilteredListArr
}

export const filterByIsVisible = (list, visibleStatus) => {
  if (visibleStatus == 0) {
    return stationsFilteredListArr
  }
  const isVisibleBoolean = visibleStatus == 1 ? true : false
  const stationsFilteredListArr = list.filter((station) => {
    const { isVisible } = station
    return isVisible == isVisibleBoolean
  })

  return stationsFilteredListArr
}

export const filterByRouteId = (list, routeId) => {
  if (routeId == null || routeId.length <= 0) {
    return stationsFilteredListArr
  }
  const stationsFilteredListArr = list.filter((station) => {
    const { routeId: stationRouteId } = station
    return stationRouteId == routeId
  })

  return stationsFilteredListArr
}

export const filterByAreaCodeId = (list, areaCodeId) => {
  if (areaCodeId == null || areaCodeId.length <= 0) {
    return stationsFilteredListArr
  }
  const stationsFilteredListArr = list.filter((station) => {
    const { routeAreaCodeId } = station
    return routeAreaCodeId == areaCodeId
  })

  return stationsFilteredListArr
}

export const stations_FilterBySearchText = (list, searchText) => {
  const stationsFilteredListArr = list.filter((station) => {
    const { stationNumber, branchName, branchAddress, lockersNumber } = station

    return (
      searchText === "" ||
      stationNumber.toString().includes(searchText) ||
      (lockersNumber && lockersNumber.toString().includes(searchText)) ||
      (branchName &&
        branchName.toLowerCase().includes(searchText.toLowerCase())) ||
      (branchAddress &&
        branchAddress.toLowerCase().includes(searchText.toLowerCase()))
    )
  })

  return stationsFilteredListArr
}

export const stations_HandleFilteredActiveStationsUpdate = (
  state,
  choosedStationsItems
) => {
  let { stationsLocalSearch } = state

  let newState = {
    ...state,
    stationsLocalSearch: {
      ...stationsLocalSearch,
      filtererActiveStations: choosedStationsItems,
      page: 1,
    },
  }

  return stations_RecalcFilteredListOutOfLatestState(newState)
}
