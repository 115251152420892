import React, { useEffect, useState } from "react"
import { Html5QrcodeScanner, Html5QrcodeScanType } from "html5-qrcode"
import { connect } from "react-redux"
import { BlueButton } from "../../../../common"
import {
  markInOrderHistory,
  clearMarkInOrderHistory,
} from "../../../../../actions"
import NotificationModal from "../../../../Modals/NotificationModal"
import "./ScanOrder.scss"

export function ScanOrder(props) {
  const [scanResult, setScanResult] = useState(null)
  const [manualSerialNumber, setManualSerialNumber] = useState("")
  const [scanner, setScanner] = useState(null)

  useEffect(() => {
    let scn = new Html5QrcodeScanner("reader", {
      supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
      qrbox: {
        width: 300,
        height: 200,
      },
      fps: 5,
    })
    setScanner(scn)
    return () => {
      if (scn) {
        scn.clear()
      }
      setScanResult(null)
      setScanner(null)
      setManualSerialNumber("")
      props.clearMarkInOrderHistory()
    }
  }, [])

  useEffect(() => {
    if (scanner) {
      scanner.render(success, error)
      function success(result) {
        setScanResult(result)
      }
      function error(err) {
        console.warn(err)
      }
    }
  }, [scanner])

  useEffect(() => {
    if (!scanResult) {
      return
    }
    props.markInOrderHistory(scanResult)
  }, [scanResult])

  const onManualInput = (number) => {
    props.markInOrderHistory(number)
  }

  return (
    <div className="scanOrder">
      <div className="orderScanner-wrapper">
        <h1>סריקת הזמנות</h1>
        <div>
          <div id="reader"></div>
          <p className="center-text">או הכנס את מספר הזמנה ידני:</p>
          <div className="center-input">
            <input
              type="text"
              value={manualSerialNumber}
              onChange={(evt) => setManualSerialNumber(evt.target.value)}
            />
            <BlueButton
              type="submit"
              extraClass="btn-manual"
              onClick={() => onManualInput(manualSerialNumber)}
            >
              שלח
            </BlueButton>
          </div>
        </div>
        <BlueButton
          type="submit"
          extraClass="btn-back"
          onClick={() => props.history.push("/HomeScreen")}
        >
          חזרה
        </BlueButton>
      </div>
      <NotificationModal
        type={"success"}
        show={props.markInOrderHistorySuccess}
        title={"סריקת הזמנה"}
        text={"ההזמנה סומנה בהצלחה "}
        onOkClick={() => props.clearMarkInOrderHistory()}
      />
      <NotificationModal
        type={"error"}
        show={props.markInOrderHistoryFail}
        title={"סריקת הזמנה"}
        text={"שגיאה בסריקת הזמנה"}
        onOkClick={() => props.clearMarkInOrderHistory()}
      />
    </div>
  )
}

const mapStateToProps = ({ ordersList }) => {
  const { markInOrderHistorySuccess, markInOrderHistoryFail } = ordersList
  return {
    markInOrderHistorySuccess,
    markInOrderHistoryFail,
  }
}

export default connect(mapStateToProps, {
  markInOrderHistory,
  clearMarkInOrderHistory,
})(ScanOrder)
