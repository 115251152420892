import React, { Component } from "react"
import { connect } from "react-redux"

import StationsFilterer from "../../../reduxified/StationsFilterer"
import StationsList from "./StationsList"
import { Pagination } from "../../../common"

import {
  fetchStations,
  fetchPriceListSelectOptions,
  selectStation,
  showStationLoading,
  setLockersStation,
  setLockersHistoryStation,
  clearLocalSearchStations,
  localSearchStationsSetPage,
  setLockersInfraSensorStation,
} from "../../../../actions"

import "./style.css"

class StationsPage extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      pagedList: [],
    }

    this.state = this.INIT_STATE
  }

  componentWillMount() {
    const { unauthorized, history } = this.props
    if (unauthorized) {
      history.push("/")
    }

    this.props.showStationLoading()
    this.props.fetchStations()
    this.props.fetchPriceListSelectOptions()
  }

  componentWillUnmount() {
    //this.props.clearLocalSearchStations();
  }

  resetCurrentPage() {
    this.props.localSearchStationsSetPage(1)
    this.setState({ pagedList: [] })
  }

  onEditBtnClick(itm) {
    const { history } = this.props
    history.push(`${this.props.match.path}/edit`)
    this.props.selectStation(itm)
  }

  onSendSmsBtnClick(item) {
    const { history } = this.props
    history.push(`${this.props.match.path}/sendSMS`)
    this.props.selectStation(item)
  }

  onCellsBtnClick(item) {
    this.props.setLockersStation(item.stationId)
    const { history } = this.props
    history.push("/LockersScreen/lockers")
  }

  onLockersStatusBtnClick(item) {
    this.props.selectStation(item)
    const { history } = this.props
    history.push("/LockersScreen/status")
  }

  onLockersHistoryBtnClick(item) {
    this.props.setLockersHistoryStation(item.stationId)
    const { history } = this.props
    history.push("/LockersScreen/history")
  }

  onLockersSensorInfraBtnClick(item) {
    this.props.setLockersInfraSensorStation(item.stationId)
    const { history } = this.props
    history.push("/LockersScreen/lockersSensorInfra")
  }

  render() {
    const { isLoading, stationsFilteredListArr, pageNum, routeAreaCodeArray } =
      this.props
    let { pagedList } = this.state
    pagedList = pagedList.map((itm) => {
      if (itm.routeAreaCodeId) {
        itm.routeAreaCode = routeAreaCodeArray[itm.routeAreaCodeId]
      }
      return itm
    })
    return (
      <div className="stations-page-wrapper">
        <StationsFilterer resetCurrentPage={() => this.resetCurrentPage()} />

        <div className="station-page" key={pagedList.length}>
          <StationsList
            items={pagedList}
            onItemEditBtnClick={(itm) => this.onEditBtnClick(itm)}
            onSendSmsBtnClick={(itm) => this.onSendSmsBtnClick(itm)}
            onLockersStatusBtnClick={(itm) => this.onLockersStatusBtnClick(itm)}
            onCellsBtnClick={(itm) => this.onCellsBtnClick(itm)}
            onLockersHistoryBtnClick={(itm) =>
              this.onLockersHistoryBtnClick(itm)
            }
            onLockersSensorInfraBtnClick={(itm) =>
              this.onLockersSensorInfraBtnClick(itm)
            }
            isLoading={isLoading}
          />
          <Pagination
            fullList={stationsFilteredListArr}
            totalPerPage={50}
            onSubListReady={(pagedList, pageNum) => {
              this.props.localSearchStationsSetPage(pageNum)
              this.setState({ pagedList })
            }}
            pageNum={pageNum}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ stationsSection, errors, routeAreaCode }) => {
  const { stations, isLoading, stationsLocalSearch } = stationsSection
  const { unauthorized } = errors
  const { page, stationsFilteredListArr } = stationsLocalSearch
  const { routeAreaCodeArray } = routeAreaCode
  return {
    unauthorized,
    isLoading,
    stationsFilteredListArr,
    stations,
    pageNum: page,
    routeAreaCodeArray,
  }
}

export default connect(mapStateToProps, {
  fetchStations,
  fetchPriceListSelectOptions,
  selectStation,
  showStationLoading,
  setLockersStation,
  setLockersHistoryStation,
  clearLocalSearchStations,
  localSearchStationsSetPage,
  setLockersInfraSensorStation,
})(StationsPage)
