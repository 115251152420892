import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import { BlueButton } from "../../../../common/BlueButton"
import {
  searchStorageSackByBarcode,
  searchStorageSackByOrderNumber,
  clearSearchStorageSacks,
  fetchOrderTypes,
  getStorageSackOrdersBySackId,
  clearGetSackOrders,
  clearOrdersSacks,
  getOrderStorageStatuses,
  printStorageSackSticker,
} from "../../../../../actions"
import CertificateOrdersTable from "../../ShippingCertificate/CertificateOrdersTable/CertificateOrdersTable.js"
import { getFormatedDate } from "../../../../../utils/FormatedDate"
import { getValEnumFromIndex, convertToSelectList } from "../../../../../utils"
import "./style.scss"

const SearchStorageSackPage = (props) => {
  const barcodeSearchTermRef = useRef("")
  const orderNumberSearchTermRef = useRef("")

  useEffect(() => {
    props.fetchOrderTypes()
    props.getOrderStorageStatuses()
    return () => {
      props.clearOrdersSacks()
      props.clearSearchStorageSacks()
    }
  }, [])

  useEffect(() => {
    if (props.searchStorageSackResult) {
      props.getStorageSackOrdersBySackId(props.searchStorageSackResult.id)
    }
  }, [props.searchStorageSackSuccess])

  const handleBarcodeSearch = () => {
    props.clearGetSackOrders()
    props.clearSearchStorageSacks()
    if (barcodeSearchTermRef.current.value != "") {
      props.searchStorageSackByBarcode(barcodeSearchTermRef.current.value)
    }
  }

  const handleOrderNumberSearch = () => {
    props.clearGetSackOrders()
    props.clearSearchStorageSacks()
    if (orderNumberSearchTermRef.current.value != "") {
      props.searchStorageSackByOrderNumber(
        orderNumberSearchTermRef.current.value
      )
    }
  }

  const showNoDataMsg = () => {
    if (props.searchStorageSackSuccess) {
      if (props.searchStorageSackResult == "") {
        return <div className="no-data">לא נמצאו שקים</div>
      }
    }
  }

  const getStorageStatusName = () => {
    return props.orderStorageStatuses.find(
      (obj) => obj.id === props.searchStorageSackResult.orderStorageStatus
    )?.statusName_he
  }

  const printSackSticker = () => {
    const sackData = {
      sackNumber: props.searchStorageSackResult?.sackNumber,
      barcode: props.searchStorageSackResult?.barcode,
      ordersCount: props.searchStorageSackResult?.ordersTotal,
      sackClosedDate: getFormatedDate(
        props.searchStorageSackResult?.createdDate
      ),
      stationId: props.searchSackOrdersResult[0]?.stationId,
      stationName: props.searchSackOrdersResult[0]?.branchName,
      stationNumber: props.searchSackOrdersResult[0]?.stationNumber,
      routeName: props.searchSackOrdersResult[0]?.routeName,
      areaCodeName: props.searchSackOrdersResult[0]?.routeAreaCode,
    }
    props.printStorageSackSticker(sackData)
  }

  return (
    <div className="SacksHistory">
      <h1>חיפוש שקי מחסן</h1>
      <div className="search-sacks no-print">
        <div className="search-input">
          <input
            type="text"
            placeholder="חפש שק לפי ברקוד"
            ref={barcodeSearchTermRef}
            className="input-field"
          />
          <BlueButton extraClass="search-button" onClick={handleBarcodeSearch}>
            חפש
          </BlueButton>
        </div>
        <div className="search-input">
          <input
            placeholder="חפש שק לפי מספר הזמנה"
            type="text"
            ref={orderNumberSearchTermRef}
            className="input-field"
          />
          <BlueButton
            extraClass="search-button"
            disabled={!orderNumberSearchTermRef.current}
            onClick={handleOrderNumberSearch}
          >
            חפש
          </BlueButton>
        </div>
        <div className="btn-print-wrapper">
          <BlueButton
            extraClass="btn-print"
            disabled={!props.searchStorageSackSuccess}
            onClick={() => printSackSticker()}
          >
            הדפס ברקוד
          </BlueButton>
        </div>
      </div>
      {props.searchStorageSackResult ? (
        <div className="sacks-found">
          <h3>פרטי שק</h3>
          <table className="result-table">
            <tbody>
              <tr>
                <th>ברקוד</th>
                <th>מספר שק</th>
                <th>סטטוס</th>
                <th>תאריך יצירה</th>
                <th>תאריך עידכון</th>
              </tr>
              {props.searchStorageSackSuccess ? (
                <tr>
                  <td>{props.searchStorageSackResult.barcode}</td>
                  <td>{props.searchStorageSackResult.sackNumber}</td>
                  <td>{getStorageStatusName()}</td>
                  <td>
                    {getFormatedDate(props.searchStorageSackResult.createdDate)}
                  </td>
                  <td>
                    {getFormatedDate(props.searchStorageSackResult.updatedDate)}
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
          {props.searchSackOrdersSuccess ? (
            <div className="orders-table">
              <h3>רשימת הזמנות בשק</h3>
              <CertificateOrdersTable
                ordersList={props.searchSackOrdersResult}
                accountTypesList={props.accountTypes}
              />
            </div>
          ) : null}
        </div>
      ) : null}
      {showNoDataMsg()}
    </div>
  )
}

const mapStateToProps = ({ orderStorageSack, selectOptions }) => {
  const {
    searchStorageSackSuccess,
    searchStorageSackFail,
    searchStorageSackResult,
    searchSackOrdersSuccess,
    searchSackOrdersResult,
  } = orderStorageSack
  const { orderTypes, orderStorageStatuses } = selectOptions
  return {
    searchStorageSackSuccess,
    searchStorageSackFail,
    searchStorageSackResult,
    orderTypes,
    orderStorageStatuses,
    searchSackOrdersSuccess,
    searchSackOrdersResult,
  }
}
export default connect(mapStateToProps, {
  searchStorageSackByBarcode,
  searchStorageSackByOrderNumber,
  getStorageSackOrdersBySackId,
  printStorageSackSticker,
  clearSearchStorageSacks,
  fetchOrderTypes,
  getOrderStorageStatuses,
  clearGetSackOrders,
  clearOrdersSacks,
})(SearchStorageSackPage)
