import {
  ON_GET_STATION_SELECT_OPTIONS_SUCCESS,
  ON_GET_LOCKER_STATUS_SELECT_OPTIONS_SUCCESS,
  ON_GET_LOCKER_TYPE_SELECT_OPTIONS_SUCCESS,
  ON_GET_PRICE_LIST_SELECT_OPTIONS_SUCCESS,
  ON_GET_STATION_SELECT_OPTIONS_FAILURE,
  ON_GET_LOCKER_STATUS_SELECT_OPTIONS_FAILURE,
  ON_GET_USER_ROLES_SUCCESS,
  ON_GET_USER_ROLES_FAILURE,
  ON_GET_ORDER_TYPES_SUCCESS,
  ON_GET_ORDER_TYPES_FAILURE,
  ON_GET_USER_SOURCES_SUCCESS,
  ON_GET_USER_SOURCES_FAILURE,
  ON_GET_ACCOUNT_TYPES_SUCCESS,
  ON_GET_ACCOUNT_TYPES_FAILURE,
  ON_GET_LOCKERS_SIZES_SUCCESS,
  ON_GET_LOCKERS_SIZES_FAILURE,
  ON_GET_DONE_ORDER_TYPES_SUCCESS,
  ON_GET_DONE_ORDER_TYPES_FAILURE,
  GET_ORDER_STORAGE_STATUSES,
  GET_ORDER_STORAGE_STATUSES_FAIL,
} from "../actions/types"

import { setOrderTypesEnumByLang } from "../utils/EnumsAndConsts"
import { getSelectLockerStatusFromArray } from "../utils/SelectOptionsFromArray"
import { convertToSelectList } from "../utils"

const INIT_STATE = {
  stations: [],
  lockerStatuses: [],
  lockerStatusSelectOptions: [],
  lockerTypes: [],
  priceLists: [],
  userRoles: [],
  orderTypes: [],
  doneOrderTypes: [],
  userSources: [],
  lockerTypeSelectOptions: [],
  accountTypes: null,
  lockersSizes: [],
  orderStorageStatuses: [],
}

const SelectOptionsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_GET_STATION_SELECT_OPTIONS_SUCCESS:
      return {
        ...state,
        stations: action.payload,
      }
    case ON_GET_LOCKER_STATUS_SELECT_OPTIONS_SUCCESS:
      const lockerStatusSelectOptions = getSelectLockerStatusFromArray(
        action.payload
      )

      return {
        ...state,
        lockerStatuses: action.payload,
        lockerStatusSelectOptions,
      }
    case ON_GET_LOCKER_TYPE_SELECT_OPTIONS_SUCCESS:
      const lockerTypeSelectOptions = convertToSelectList(action.payload)
      return {
        ...state,
        lockerTypes: action.payload,
        lockerTypeSelectOptions,
      }
    case ON_GET_PRICE_LIST_SELECT_OPTIONS_SUCCESS:
      return {
        ...state,
        priceLists: action.payload,
      }
    case ON_GET_USER_ROLES_SUCCESS:
      return {
        ...state,
        userRoles: action.payload,
      }
    case ON_GET_ORDER_TYPES_SUCCESS:
      return {
        ...state,
        orderTypes: setOrderTypesEnumByLang(action.payload),
      }
    case ON_GET_DONE_ORDER_TYPES_SUCCESS:
      return {
        ...state,
        doneOrderTypes: action.payload,
      }
    case ON_GET_USER_SOURCES_SUCCESS:
      return {
        ...state,
        userSources: action.payload,
      }
    case ON_GET_USER_SOURCES_FAILURE:
      return {
        ...state,
        userSources: [],
      }
    case ON_GET_ACCOUNT_TYPES_SUCCESS:
      return {
        ...state,
        accountTypes: action.payload,
      }

    case ON_GET_LOCKERS_SIZES_SUCCESS:
      return {
        ...state,
        lockersSizes: action.payload,
      }
    case ON_GET_LOCKERS_SIZES_FAILURE:
      return {
        ...state,
        lockersSizes: [],
      }
    case GET_ORDER_STORAGE_STATUSES:
      return {
        ...state,
        orderStorageStatuses: action.payload,
      }
    case GET_ORDER_STORAGE_STATUSES_FAIL:
      return {
        ...state,
        orderStorageStatuses: [],
      }
    default:
      return state
  }
}

export default SelectOptionsReducer
