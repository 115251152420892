import axios from "axios"
import api from "../api"
import {
  ON_LOADING_ORDERS_SACKS,
  ON_CREATE_ORDER_SACK_SUCCESS,
  ON_CREATE_ORDER_SACK_FAIL,
  GET_ORDER_SACKS_LIST_SUCCESS,
  GET_ORDER_SACKS_LIST_FAIL,
  ON_FETCH_ORDERS_FOR_SACK_SUCCESS,
  ON_FETCH_ORDERS_FOR_SACK_FAIL,
  ON_PUT_ORDER_TO_SACK_SUCCESS,
  ON_PUT_ORDER_TO_SACK_FAIL,
  ON_GET_SACK_ORDERS_SUCCESS,
  ON_GET_SACK_ORDERS_FAIL,
  ON_SET_ORDERS_SACK_DELEVERED_SUCCESS,
  ON_SET_ORDERS_SACK_DELEVERED_FAIL,
  CLEAR_ORDERS_SACKS,
  CLEAR_SELECT_ORDERS_SACK,
  ON_PUT_ORDERS_TO_SACK_SUCCESS,
  ON_PUT_ORDERS_TO_SACK_FAIL,
  ON_SEARCH_SACK_SUCCESS,
  ON_SEARCH_SACK_FAIL,
  CLEAR_SEARCH_SACK,
  CLEAR_GET_SACKS_ORDERS,
  ON_SET_ORDERS_SACK_DELEVERED_BY_BARCODE_SUCCESS,
  ON_SET_ORDERS_SACK_DELEVERED_BY_BARCODE_FAIL,
  CLEAR_SET_ORDERS_SACK_DELEVERED_BY_BARCODE,
  ON_SET_ORDERS_SACK_DELEVERED_BY_BARCODE_CONFLICT,
  ON_SEARCH_SACKS_LIST_SUCCESS,
  ON_SEARCH_SACKS_LIST_FAIL,
  CLEAR_SEARCH_SACKS_LIST,
  ON_GET_WAREHOUSE_LOCATION_SUCCESS,
  ON_GET_WAREHOUSE_LOCATION_FAIL,
} from "./types"
import errorManager from "../utils/ErrorManager"

export const onLoadingOrdersSacks = () => {
  return (dispatch) => {
    dispatch({
      type: ON_LOADING_ORDERS_SACKS,
    })
  }
}

export const createOrderSackByAccount = (accountType) => {
  return (dispatch) => {
    axios
      .get(api.createOrderSackByAccount(accountType))
      .then((res) => {
        dispatch({
          type: ON_CREATE_ORDER_SACK_SUCCESS,
          payload: res.data,
        })

        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_CREATE_ORDER_SACK_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const fetchOrderSacksList = () => {
  return (dispatch) => {
    axios
      .get(api.fetchOrderSacksList())
      .then((res) => {
        dispatch({
          type: GET_ORDER_SACKS_LIST_SUCCESS,
          payload: { data: res.data },
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: GET_ORDER_SACKS_LIST_FAIL,
          payload: { data: err },
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const fetchOrderSacksListByAccount = (accountType, orderStatus) => {
  return (dispatch) => {
    axios
      .get(api.fetchOrderSacksListByAccount(accountType, orderStatus))
      .then((res) => {
        dispatch({
          type: GET_ORDER_SACKS_LIST_SUCCESS,
          payload: { data: res.data, accountType: accountType },
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: GET_ORDER_SACKS_LIST_FAIL,
          payload: { data: err, accountType: accountType },
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const fetchOrdersForSack = (orderType) => {
  return (dispatch) => {
    axios
      .get(api.fetchOrdersForSack(orderType))
      .then((res) => {
        dispatch({
          type: ON_FETCH_ORDERS_FOR_SACK_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_FETCH_ORDERS_FOR_SACK_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const putOrderToSack = (orderId, sackId, orderType) => {
  return (dispatch) => {
    axios
      .get(api.putOrderToSack(orderId, sackId))
      .then((res) => {
        dispatch({
          type: ON_PUT_ORDER_TO_SACK_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_PUT_ORDER_TO_SACK_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const putOrdersToSack = (sackId, ordersIds) => {
  return (dispatch) => {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    }
    axios
      .put(api.putOrdersToSack(sackId), ordersIds, options)
      .then((res) => {
        dispatch({
          type: ON_PUT_ORDERS_TO_SACK_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_PUT_ORDERS_TO_SACK_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const fetchSackOrders = (sackId) => {
  return (dispatch) => {
    axios
      .get(api.fetchSackOrders(sackId))
      .then((res) => {
        dispatch({
          type: ON_GET_SACK_ORDERS_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_GET_SACK_ORDERS_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const setOrderSackDelevered = (sackId) => {
  return (dispatch) => {
    axios
      .get(api.setOrderSackDelevered(sackId))
      .then((res) => {
        dispatch({
          type: ON_SET_ORDERS_SACK_DELEVERED_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_SET_ORDERS_SACK_DELEVERED_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const setOrderSackDeleveredByBarcode = (
  barcode,
  isInWarehouseRadius
) => {
  return (dispatch) => {
    axios
      .get(api.setOrderSackDeleveredByBarcode(barcode, isInWarehouseRadius))
      .then((res) => {
        dispatch({
          type: ON_SET_ORDERS_SACK_DELEVERED_BY_BARCODE_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        if (err.response.status === 409) {
          dispatch({
            type: ON_SET_ORDERS_SACK_DELEVERED_BY_BARCODE_CONFLICT,
            payload: err,
          })
        } else {
          dispatch({
            type: ON_SET_ORDERS_SACK_DELEVERED_BY_BARCODE_FAIL,
            payload: err,
          })
        }

        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const clearSetOrderSackDeleveredByBarcode = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SET_ORDERS_SACK_DELEVERED_BY_BARCODE,
    })
  }
}

export const clearOrdersSacks = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ORDERS_SACKS,
    })
  }
}

export const clearSelectOrdersSack = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SELECT_ORDERS_SACK,
    })
  }
}

export const searchSackByBarcode = (barcode) => {
  return (dispatch) => {
    axios
      .get(api.searchSackByBarcode(barcode))
      .then((res) => {
        dispatch({
          type: ON_SEARCH_SACK_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_SEARCH_SACK_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const searchSackByOrderNumber = (orderNumber) => {
  return (dispatch) => {
    axios
      .get(api.searchSackByOrderNumber(orderNumber))
      .then((res) => {
        dispatch({
          type: ON_SEARCH_SACK_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_SEARCH_SACK_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const searchSacksByAccount = (accountType) => {
  return (dispatch) => {
    axios
      .get(api.searchSacksByAccount(accountType))
      .then((res) => {
        dispatch({
          type: ON_SEARCH_SACKS_LIST_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_SEARCH_SACKS_LIST_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const searchSacksByDeliverPhone = (phoneNumber) => {
  return (dispatch) => {
    axios
      .get(api.searchSacksByDeliverPhone(phoneNumber))
      .then((res) => {
        dispatch({
          type: ON_SEARCH_SACKS_LIST_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_SEARCH_SACKS_LIST_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const clearSearchSack = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SEARCH_SACK,
    })
  }
}

export const clearGetSackOrders = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_GET_SACKS_ORDERS,
    })
  }
}

export const clearSearchSacksList = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SEARCH_SACKS_LIST,
    })
  }
}

export const getWarehouseLocation = () => {
  return (dispatch) => {
    axios
      .get(api.getWarehouseLocation)
      .then((res) => {
        dispatch({
          type: ON_GET_WAREHOUSE_LOCATION_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_GET_WAREHOUSE_LOCATION_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}
