import axios from "axios"
import api from "../api"
import {
  getOrderForPrinterService,
  getOrderForPrinterServiceReturnToSource,
} from "../utils/OrderUnpacker"
export const printPinkLabel = (orderNumber, lang) => {
  return (dispatch) => {
    axios
      .post(api.printPinkLabel, { orderNumber, lang })
      .then((res) => console.log("printPinkLabel RESPONSE - ", res.data))
      .catch((err) => console.log("printPinkLabel - ERR ", err))
  }
}

export const printHydroLabel = (printObject) => {
  return (dispatch) => {
    const data = printObject //{          "name": "יוסי",          "orderNumber":"2323"      };
    axios
      .post(api.printHydroLabel, data)
      .then((res) => console.log("printHydroLabel RESPONSE - ", res.data))
      .catch((err) => console.log("printHydroLabel - ERR ", err))
  }
}

export const printWhiteReceipt = (order) => {
  let printerServiceOrder = null
  if (order?.orderType === 5 && order.isReturnToSource === true) {
    printerServiceOrder = getOrderForPrinterServiceReturnToSource(order)
  } else {
    printerServiceOrder = getOrderForPrinterService(order)
  }

  return (dispatch) => {
    axios
      .post(api.printWhiteReceipt, printerServiceOrder)
      .then((res) => console.log("printWhiteReceipt RESPONSE - ", res.data))
      .catch((err) => console.log("printWhiteReceipt - ERR ", err))
  }
}

export const printSackSticker = (orderNumber, orderType, lang) => {
  return (dispatch) => {
    axios
      .post(api.printSackSticker, { orderNumber, orderType, lang })
      .then((res) => console.log("printSackSticker RESPONSE - ", res.data))
      .catch((err) => console.log("printSackSticker - ERR ", err))
  }
}

export const printStorageSackSticker = (order) => {
  return (dispatch) => {
    axios
      .post(api.printStorageSackSticker, order)
      .then((res) => console.log("printSackSticker RESPONSE - ", res.data))
      .catch((err) => console.log("printSackSticker - ERR ", err))
  }
}
