import React, { useEffect, useRef, useState } from "react"
import { Route, Link } from "react-router-dom"
import { connect } from "react-redux"
import NotificationModal from "../../../../Modals/NotificationModal"
import YesNoPopup from "../../../../Modals/YesNoPopup/yesNoPopup.js"
import {
  fetchAllOrderStatuses,
  onLoadingOrdersSacks,
  fetchOrderSacksListByAccount,
  fetchOrdersForSack,
  putOrderToSack,
  fetchSackOrders,
  setOrderSackDelevered,
  clearOrdersSacks,
  clearSelectOrdersSack,
  fetchAccountTypes,
  fetchOrderSacksList,
} from "../../../../../actions"
import { fetchIfEmpty } from "../../../../../utils"
import CertificateOrdersTable from "../../ShippingCertificate/CertificateOrdersTable/CertificateOrdersTable.js"
import SacksList from "./SacksList"
import Barcode from "react-barcode"
import SacksHistory from "./SacksHistory/SacksHistory.js"
import "./style.scss"

const SacksPage = (props) => {
  const [state, setState] = useState({
    serviceName: props.history.location.state,
    showToDeliverSackQuestion: false,
    currentSack: null,
    currentSackBarcode: "",
    activeSacksListFiltered: [],
  })
  const [accountTypesList, setAccountTypesList] = useState([])
  const [selectedSackBarcode, setSelectedSackBarcode] = useState("")
  const [isSackNotFound, setIsSackNotFound] = useState(false)
  const [isShowPrintPage, setIsShowPrintPage] = useState(false)
  const [successMsg, setSuccessMsg] = useState("")
  const sackBarcodeInput = useRef(null)
  const notAllowedAccountTypes = [2, 3, 4, 5, 7, 11, 13, 17, 18, 21, 23, 24, 28]

  useEffect(() => {
    const { unauthorized, history, ordersStatusesArr, accountTypes } = props

    if (unauthorized) {
      history.push("/")
    }

    fetchIfEmpty(ordersStatusesArr, props.fetchAllOrderStatuses)
    fetchIfEmpty(accountTypes, props.fetchAccountTypes)
    props.fetchOrderSacksList()
    const handleGlobalClick = (event) => {
      if (
        sackBarcodeInput.current &&
        !sackBarcodeInput.current.contains(event.target)
      ) {
        sackBarcodeInput.current.focus()
      }
    }
    document.addEventListener("click", handleGlobalClick)
    return () => {
      clearSelectOrdersSack()
      props.clearOrdersSacks()
      document.removeEventListener("click", handleGlobalClick)
    }
  }, [])

  useEffect(() => {
    if (props.accountTypes) {
      const listItems = Object.entries(props.accountTypes)
        .filter(
          ([value]) => !notAllowedAccountTypes.includes(parseInt(value, 10))
        )
        .map(([value, label]) => ({
          value: parseInt(value, 10),
          label,
        }))
      setAccountTypesList(listItems)
    }
  }, [props.accountTypes])

  useEffect(() => {
    if (props.activeSacksList?.length > 0) {
      const newList = props.activeSacksList.filter((elm) => elm.ordersCount > 0)
      setState(() => ({ activeSacksListFiltered: newList }))
    }
  }, [props.activeSacksList])

  useEffect(() => {
    if (isShowPrintPage && props.sackOrders.length > 0) {
      printCertificateOrdersTable()
      setIsShowPrintPage(false)
    }
  }, [props.getSackOrdersSuccess, isShowPrintPage])

  useEffect(() => {
    if (props.deliveredOrdersSack) {
      if (props.deliveredOrdersSack?.orderStatus == 22) {
        setSuccessMsg('הזמנה עודכנה לסטטוס "יצא מהמחסן"')
      }
      if (props.deliveredOrdersSack?.orderStatus == 12) {
        setSuccessMsg('הזמנה עודכנה לסטטוס "הוחזר לשולח"')
      }
    }
  }, [props.deliveredOrdersSack])

  const printCertificateOrdersTable = () => {
    window.print()
  }

  const clearSelectOrdersSack = () => {
    setState((prevState) => ({
      ...prevState,
      currentSackBarcode: "",
      showToDeliverSackQuestion: false,
    }))
    setSuccessMsg("")
    props.onLoadingOrdersSacks()
    props.fetchOrderSacksList()
    if (sackBarcodeInput?.current) {
      sackBarcodeInput.current.value = ""
      sackBarcodeInput.current.focus()
    }
  }

  const onScanSackBarcode = (barcode) => {
    if (state.activeSacksListFiltered.length == 0) return
    setState((prevState) => ({
      ...prevState,
      currentSackBarcode: barcode,
    }))
    if (barcode?.length === 8) {
      const sack = state.activeSacksListFiltered?.find(
        (elm) => elm.barcode === barcode
      )
      if (!sack) {
        setIsSackNotFound(true)
        clearSelectOrdersSack()
      } else {
        setState((prevState) => ({
          ...prevState,
          currentSack: sack,
          showToDeliverSackQuestion: true,
        }))
      }
    }
  }

  const onDeliverSackClick = (sack) => {
    setState((prevState) => ({
      ...prevState,
      currentSack: sack,
      showToDeliverSackQuestion: true,
    }))
  }

  const onDeliverSack = () => {
    const { currentSack } = state
    if (currentSack) {
      props.onLoadingOrdersSacks()
      props.setOrderSackDelevered(currentSack.id)
      setState((prevState) => ({
        ...prevState,
        showToDeliverSackQuestion: false,
      }))
    }
  }

  const onPrintClick = (sack) => {
    setSelectedSackBarcode(sack.barcode)
    props.fetchSackOrders(sack.id)
    setIsShowPrintPage(true)
  }

  const showSuccessMsg = () => {}

  const {
    showToDeliverSackQuestion,
    currentSackBarcode,
    currentSack,
    activeSacksListFiltered,
  } = state
  const { isLoading, isError, accountTypes } = props
  const accountName = accountTypes ? accountTypes[currentSack?.accountType] : ""
  const confirmMessage = "האם להמשיך שיחרור שק לספק " + accountName + "?"

  return (
    <div className="order-sacks-list-wrapper">
      <div className="not-printable">
        <h1 className="title">סריקת תעודת משלוח להחזרות</h1>

        <div className="search-wrapper">
          <input
            autoFocus
            ref={sackBarcodeInput}
            placeholder={"חפש שק"}
            value={currentSackBarcode}
            onChange={(evt) => onScanSackBarcode(evt.target.value)}
          />
        </div>
        <div className="list-counter">
          {activeSacksListFiltered.length} שקים
        </div>

        <SacksList
          items={activeSacksListFiltered}
          isLoading={isLoading}
          isError={isError}
          history={props.history}
          accountTypes={props.accountTypes}
          onDeliverSackClick={(e) => onDeliverSackClick(e)}
          onPrintClick={(e) => onPrintClick(e)}
        />

        <NotificationModal
          show={props.isError}
          type={"error"}
          title={"יצירת שק חדש מסוג " + accountName}
          text={"שים לב! הפעולה לא הצליחה."}
          onOkClick={() => clearSelectOrdersSack()}
        />

        <NotificationModal
          show={successMsg}
          title={"הפעולה הצליחה."}
          text={successMsg}
          onOkClick={() => clearSelectOrdersSack()}
        />

        <NotificationModal
          type={"warning"}
          show={isSackNotFound}
          title={"שק לא נמצא"}
          text={"שק לא נמצא במערכת."}
          onOkClick={() => setIsSackNotFound(false)}
        />

        <YesNoPopup
          isOpen={showToDeliverSackQuestion}
          infoMsg={confirmMessage}
          close={() => clearSelectOrdersSack()}
          confirm={() => onDeliverSack()}
        />
      </div>

      <div className="printable">
        {props.getSackOrdersSuccess ? (
          <div>
            <div className="barcode-wrapper">
              <Barcode value={selectedSackBarcode} />
            </div>
            <CertificateOrdersTable
              ordersList={props.sackOrders}
              accountTypesList={accountTypesList}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

const mapStateToProps = ({
  errors,
  orderSack,
  selectOptions,
  ordersStatuses,
}) => {
  const { unauthorized } = errors
  const { ordersStatusesArr } = ordersStatuses
  const { accountTypes } = selectOptions
  const {
    activeSacksList,
    deliveredOrdersSack,
    sackOrders,
    getSackOrdersSuccess,
    getSackOrdersFail,
    isLoading,
    isError,
  } = orderSack

  return {
    unauthorized,
    ordersStatusesArr,
    activeSacksList,
    deliveredOrdersSack,
    sackOrders,
    getSackOrdersSuccess,
    getSackOrdersFail,
    accountTypes,
    isLoading,
    isError,
  }
}

export default connect(mapStateToProps, {
  fetchAllOrderStatuses,
  onLoadingOrdersSacks,
  fetchOrderSacksListByAccount,
  fetchOrdersForSack,
  putOrderToSack,
  fetchSackOrders,
  setOrderSackDelevered,
  clearOrdersSacks,
  clearSelectOrdersSack,
  fetchAccountTypes,
  fetchOrderSacksList,
})(SacksPage)
