import React, { Component } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import {
  fetchAllStationData,
  updateFiltererActiveTypes,
  updateFiltererActiveStatuses,
  updateFiltererActiveStations,
  updateFiltererIsPaidStatus,
  fetchOrderTypes,
  fetchAllOrderStatuses,
  localSearchStationsByText,
  localSearchStationsByPaymentVersion,
  localSearchStationsByIsAlive,
  localSearchStationsByIsVisible,
  localSearchStationsByRoute,
  localSearchStationsByAreaCode,
  localSearchStationsByStations,
  fetchRoutes,
  getAllRouteAreaCodes,
  clearLocalSearchStations,
} from "../../../actions"
import { fetchIfEmpty } from "../../../utils"
import {
  paymentVersionList,
  stationIsAliveSelector,
  stationIsVisibleSelector,
} from "../../../utils/EnumsAndConsts"
import { InputFiled, SelectMultiAutocomplete } from "../../common"

import "./style.css"

class StationsFilterer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      paymentVersion: 0,
      isAliveStatus: 0,
      isVisibleStatus: 0,
      routeId: null,
      areaCode: null,
    }
  }

  componentDidMount() {
    this.props.fetchAllStationData()
    fetchIfEmpty(this.props.orderTypes, this.props.fetchOrderTypes)
    fetchIfEmpty(this.props.statusesArr, this.props.fetchAllOrderStatuses)
    fetchIfEmpty(this.props.routeList, this.props.fetchRoutes)
    fetchIfEmpty(this.props.areaCodesList, this.props.getAllRouteAreaCodes)
  }

  componentWillUnmount() {
    this.props.clearLocalSearchStations()
  }

  updateActiveStations(items, index) {
    this.props.resetCurrentPage()
    this.props.localSearchStationsByStations(items)
    this.setState({ paymentVersion: 0 })
    this.props.localSearchStationsByPaymentVersion(0)
    this.setState({ isAliveStatus: 0 })
    this.props.localSearchStationsByIsAlive(0)
    this.setState({ isVisibleStatus: 0 })
    this.props.localSearchStationsByIsVisible(0)
    this.setState({ routeId: null })
    this.props.localSearchStationsByRoute(null)
    this.setState({ areaCode: null })
    this.props.localSearchStationsByAreaCode(null)
  }

  changePaymnetVersionHandler(version) {
    this.setState({ paymentVersion: version })
    this.props.localSearchStationsByPaymentVersion(version)
  }

  changeRouteHandler(value) {
    this.setState({ routeId: value })
    this.props.resetCurrentPage()
    this.props.localSearchStationsByRoute(value)
  }

  changeAreaCodeHandler(value) {
    this.setState({ areaCode: value })
    this.props.resetCurrentPage()
    this.props.localSearchStationsByAreaCode(value)
  }

  changeStationIsAliveHandler(isAlive) {
    this.setState({ isAliveStatus: isAlive })
    this.props.localSearchStationsByIsAlive(isAlive)
  }

  changeStationIsVisibleHandler(isVisible) {
    this.setState({ isVisibleStatus: isVisible })
    this.props.localSearchStationsByIsVisible(isVisible)
  }

  render() {
    const {
      stationsItemSelectList,
      searchText,
      filtererActiveStations,
      routesList,
      areaCodesList,
    } = this.props
    const {
      paymentVersion,
      isAliveStatus,
      isVisibleStatus,
      routeId,
      areaCode,
    } = this.state
    return (
      <div className="stations-filterer">
        <InputFiled
          extraClass="search-input"
          placeholder="חיפוש מספר/שם/כתובת עמדה"
          value={searchText}
          alt="ניתן לחפש הזמנה לפי מספר הזמנה או מספר חבילה. ניתן לחפש לקוח לפי טלפון או שם."
          icon="magnifying_glass"
          onChange={(e) => {
            this.props.resetCurrentPage()
            this.props.localSearchStationsByText(e.target.value)
          }}
        />
        <SelectMultiAutocomplete
          placeholder="עמדה"
          items={stationsItemSelectList}
          value={filtererActiveStations}
          onChangeHandler={(itm) => this.updateActiveStations(itm)}
          extraClassName="sations-list"
        />

        <div
          key={"paymentVersion" + paymentVersion}
          id="paymentVersionSelector"
          className="selector"
        >
          <Select
            options={paymentVersionList}
            value={paymentVersionList[paymentVersion]}
            onChange={(e) => this.changePaymnetVersionHandler(e.value)}
            placeholder="בחר גירסת תשלום"
          />
        </div>
        <div key={"route" + routeId} id="routeSelector" className="selector">
          <Select
            options={routesList}
            value={routesList.map((route) => {
              if (route.value === routeId) {
                return route
              }
            })}
            onChange={(e) => this.changeRouteHandler(e.value)}
          />
        </div>
        <div
          key={"areaCode" + areaCode}
          id="areaCodeSelector"
          className="selector"
        >
          <Select
            options={areaCodesList}
            value={areaCodesList.map((routeAreaCode) => {
              if (routeAreaCode.value === areaCode) {
                return routeAreaCode
              }
            })}
            onChange={(e) => this.changeAreaCodeHandler(e.value)}
          />
        </div>

        <div
          key={"isAliveStatus" + isAliveStatus}
          id="AliveStationSelector"
          className="selector"
        >
          <Select
            options={stationIsAliveSelector}
            value={stationIsAliveSelector[isAliveStatus]}
            onChange={(e) => this.changeStationIsAliveHandler(e.value)}
          />
        </div>

        <div
          key={"isVisibleStatus" + isVisibleStatus}
          id="visibleStationSelector"
          className="selector"
        >
          <Select
            options={stationIsVisibleSelector}
            value={stationIsVisibleSelector[isVisibleStatus]}
            onChange={(e) => this.changeStationIsVisibleHandler(e.value)}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  stationsSection,
  stationsList,
  routes,
  routeAreaCode,
}) => {
  const { stationsLocalSearch } = stationsSection
  const { searchText, filtererActiveStations } = stationsLocalSearch
  const { allStationsArr, stationsItemSelectList } = stationsList
  const { routesSelectList: routesList } = routes
  const { routeAreaCodesSelectList: areaCodesList } = routeAreaCode

  return {
    allStationsArr,
    stationsItemSelectList,
    searchText,
    filtererActiveStations,
    routesList,
    areaCodesList,
  }
}

export default connect(mapStateToProps, {
  fetchAllStationData,
  updateFiltererActiveTypes,
  updateFiltererActiveStatuses,
  updateFiltererActiveStations,
  updateFiltererIsPaidStatus,
  fetchOrderTypes,
  fetchAllOrderStatuses,
  localSearchStationsByText,
  localSearchStationsByStations,
  localSearchStationsByPaymentVersion,
  localSearchStationsByIsAlive,
  localSearchStationsByIsVisible,
  localSearchStationsByRoute,
  localSearchStationsByAreaCode,
  fetchRoutes,
  getAllRouteAreaCodes,
  clearLocalSearchStations,
})(StationsFilterer)
