import {
  ON_LOADING_ORDERS_SACKS,
  ON_CREATE_ORDER_SACK_SUCCESS,
  ON_CREATE_ORDER_SACK_FAIL,
  GET_ORDER_SACKS_LIST_SUCCESS,
  GET_ORDER_SACKS_LIST_FAIL,
  ON_FETCH_ORDERS_FOR_SACK_SUCCESS,
  ON_FETCH_ORDERS_FOR_SACK_FAIL,
  ON_PUT_ORDER_TO_SACK_SUCCESS,
  ON_PUT_ORDER_TO_SACK_FAIL,
  ON_GET_SACK_ORDERS_SUCCESS,
  ON_GET_SACK_ORDERS_FAIL,
  ON_SET_ORDERS_SACK_DELEVERED_SUCCESS,
  ON_SET_ORDERS_SACK_DELEVERED_FAIL,
  ON_SET_ORDERS_SACK_DELEVERED_BY_BARCODE_SUCCESS,
  ON_SET_ORDERS_SACK_DELEVERED_BY_BARCODE_FAIL,
  CLEAR_SET_ORDERS_SACK_DELEVERED_BY_BARCODE,
  ON_SET_ORDERS_SACK_DELEVERED_BY_BARCODE_CONFLICT,
  CLEAR_SELECT_ORDERS_SACK,
  CLEAR_ORDERS_SACKS,
  ON_USER_SIGN_IN_RESET_ATTEMPTS,
  ON_PUT_ORDERS_TO_SACK_SUCCESS,
  ON_PUT_ORDERS_TO_SACK_FAIL,
  ON_SEARCH_SACK_SUCCESS,
  ON_SEARCH_SACK_FAIL,
  CLEAR_SEARCH_SACK,
  CLEAR_GET_SACKS_ORDERS,
  ON_SEARCH_SACKS_LIST_SUCCESS,
  ON_SEARCH_SACKS_LIST_FAIL,
  CLEAR_SEARCH_SACKS_LIST,
  ON_GET_WAREHOUSE_LOCATION_SUCCESS,
  ON_GET_WAREHOUSE_LOCATION_FAIL,
  UNAUTHORIZED,
} from "../actions/types"

const INIT_STATE = {
  sacksList: [],
  activeSacksList: [],
  ordersForSack: [],
  sackOrders: [],
  getSackOrdersSuccess: false,
  getSackOrdersFail: false,
  createdSack: null,
  puttedToSackOrder: null,
  deliveredOrdersSack: null,
  isLoading: false,
  isError: false,
  putOrdersToSackSuccess: false,
  putOrdersToSackFail: false,
  searchSackResult: null,
  searchSackSuccess: false,
  searchSackFail: false,
  searchSacksListResult: null,
  searchSacksListSuccess: false,
  searchSacksListFail: false,
  deliveredOrdersSackBybarcode: null,
  deliveredOrdersSackBybarcodeSuccess: false,
  deliveredOrdersSackBybarcodeFail: false,
  deliveredOrdersSackBybarcodeConflit: false,
  warehouseLocation: null,
}

const OrderSackReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_LOADING_ORDERS_SACKS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        puttedToSackOrder: null,
        createdSack: null,
        deliveredOrdersSack: null,
      }
    case ON_CREATE_ORDER_SACK_SUCCESS:
      return {
        ...state,
        createdSack: action.payload,
        isLoading: false,
        isError: false,
      }
    case ON_CREATE_ORDER_SACK_FAIL:
      return {
        ...state,
        createdSack: null,
        isLoading: false,
        isError: true,
      }
    case GET_ORDER_SACKS_LIST_SUCCESS:
      return {
        ...state,
        activeSacksList: action.payload.data,
        isLoading: false,
        isError: false,
      }

    case GET_ORDER_SACKS_LIST_FAIL:
      return {
        ...state,
        activeSacksList: [],
        isLoading: false,
        isError: true,
      }

    case ON_FETCH_ORDERS_FOR_SACK_SUCCESS:
      return {
        ...state,
        ordersForSack: action.payload,
        isLoading: false,
        isError: false,
      }
    case ON_FETCH_ORDERS_FOR_SACK_FAIL:
      return {
        ...state,
        sacksList: [],

        isLoading: false,
        isError: true,
      }
    case ON_PUT_ORDER_TO_SACK_SUCCESS:
      return {
        ...state,
        puttedToSackOrder: action.payload,
        isLoading: false,
        isError: false,
      }
    case ON_PUT_ORDER_TO_SACK_FAIL:
      return {
        ...state,
        puttedToSackOrder: null,
        isLoading: false,
        isError: true,
      }
    case ON_PUT_ORDERS_TO_SACK_SUCCESS:
      return {
        ...state,
        putOrdersToSackSuccess: true,
        putOrdersToSackFail: false,
        isLoading: false,
        isError: false,
      }
    case ON_PUT_ORDERS_TO_SACK_FAIL:
      return {
        ...state,
        putOrdersToSackSuccess: false,
        putOrdersToSackFail: true,
        isLoading: false,
        isError: true,
      }
    case ON_GET_SACK_ORDERS_SUCCESS:
      return {
        ...state,
        sackOrders: action.payload,
        getSackOrdersSuccess: true,
        getSackOrdersFail: false,
        isLoading: false,
        isError: false,
      }
    case ON_GET_SACK_ORDERS_FAIL:
      return {
        ...state,
        sackOrders: [],
        getSackOrdersSuccess: false,
        getSackOrdersFail: true,
        isLoading: false,
        isError: true,
      }
    case ON_SET_ORDERS_SACK_DELEVERED_SUCCESS:
      return {
        ...state,
        deliveredOrdersSack: action.payload,
        isLoading: false,
        isError: false,
      }
    case ON_SET_ORDERS_SACK_DELEVERED_FAIL:
      return {
        ...state,
        deliveredOrdersSack: null,
        isLoading: false,
        isError: true,
      }
    case CLEAR_SELECT_ORDERS_SACK:
      return {
        ...state,
        ordersForSack: [],
        sackOrders: [],
        createdSack: null,
        puttedToSackOrder: null,
        deliveredOrdersSack: null,
        getSackOrdersSuccess: false,
        getSackOrdersFail: false,
        isLoading: false,
        isError: false,
      }
    case CLEAR_ORDERS_SACKS:
    case ON_USER_SIGN_IN_RESET_ATTEMPTS:
    case UNAUTHORIZED:
      return {
        sacksList: [],
        activeSacksList: [],
        ordersForSack: [],
        sackOrders: [],
        getSackOrdersSuccess: false,
        getSackOrdersFail: false,
        createdSack: null,
        puttedToSackOrder: null,
        deliveredOrdersSack: null,
        isLoading: false,
        isError: false,
        putOrdersToSackSuccess: false,
        putOrdersToSackFail: false,
      }
    case ON_SEARCH_SACK_SUCCESS:
      return {
        ...state,
        searchSackSuccess: true,
        searchSackFail: false,
        searchSacksListResult: null,
        searchSacksListSuccess: false,
        searchSackResult: action.payload,
        isLoading: false,
      }
    case ON_SEARCH_SACK_FAIL:
      return {
        ...state,
        searchSackSuccess: false,
        searchSackFail: true,
        searchSackResult: null,
        isLoading: false,
      }
    case CLEAR_SEARCH_SACK:
      return {
        ...state,
        searchSackSuccess: false,
        searchSackFail: false,
        searchSackResult: null,
        isLoading: false,
      }
    case CLEAR_GET_SACKS_ORDERS:
      return {
        ...state,
        sackOrders: [],
        getSackOrdersSuccess: false,
        getSackOrdersFail: false,
        isLoading: false,
      }
    case ON_SET_ORDERS_SACK_DELEVERED_BY_BARCODE_SUCCESS:
      return {
        ...state,
        deliveredOrdersSackBybarcode: action.payload,
        deliveredOrdersSackBybarcodeSuccess: true,
        deliveredOrdersSackBybarcodeFail: false,
        isLoading: false,
      }
    case ON_SET_ORDERS_SACK_DELEVERED_BY_BARCODE_FAIL:
      return {
        ...state,
        deliveredOrdersSackBybarcode: null,
        deliveredOrdersSackBybarcodeSuccess: false,
        deliveredOrdersSackBybarcodeFail: true,
        isLoading: false,
      }
    case ON_SET_ORDERS_SACK_DELEVERED_BY_BARCODE_CONFLICT:
      return {
        ...state,
        deliveredOrdersSackBybarcode: null,
        deliveredOrdersSackBybarcodeSuccess: false,
        deliveredOrdersSackBybarcodeConflit: true,
        isLoading: false,
      }
    case CLEAR_SET_ORDERS_SACK_DELEVERED_BY_BARCODE:
      return {
        ...state,
        deliveredOrdersSackBybarcode: null,
        deliveredOrdersSackBybarcodeSuccess: false,
        deliveredOrdersSackBybarcodeFail: false,
        deliveredOrdersSackBybarcodeConflit: false,
        isLoading: false,
      }
    case ON_SEARCH_SACKS_LIST_SUCCESS:
      return {
        ...state,
        searchSacksListSuccess: true,
        searchSacksListFail: false,
        searchSackSuccess: false,
        searchSackResult: null,
        sackOrders: [],
        getSackOrdersSuccess: false,
        searchSacksListResult: action.payload,
        isLoading: false,
      }
    case ON_SEARCH_SACKS_LIST_FAIL:
      return {
        ...state,
        searchSacksListSuccess: false,
        searchSacksListFail: true,
        searchSacksListResult: null,
        isLoading: false,
      }

    case CLEAR_SEARCH_SACKS_LIST:
      return {
        ...state,
        searchSacksListSuccess: false,
        searchSacksListFail: false,
        searchSacksListResult: null,
        isLoading: false,
      }

    case ON_GET_WAREHOUSE_LOCATION_SUCCESS:
      return {
        ...state,
        warehouseLocation: action.payload,
        isLoading: false,
      }

    case ON_GET_WAREHOUSE_LOCATION_FAIL:
      return {
        ...state,
        warehouseLocation: null,
        isLoading: false,
      }
    default:
      return state
  }
}

export default OrderSackReducer
