import axios from "axios"
import api from "../api"

import {
  ON_GET_ORDERS_LIST_FAIL,
  ON_GET_ORDERS_LIST_SUCCESS,
  ON_GET_ORDER_DETAILS_SUCCESS,
  ON_ORDER_FILTER_BY_CHNAGE,
  SET_EXTERNAL_ORDER_TO_EDIT,
  ON_FILTERED_ACTIVE_TYPES_UPDATE,
  ON_FILTERED_ACTIVE_STATUSES_UPDATE,
  ON_FILTERED_ACTIVE_STATIONS_UPDATE,
  ON_FILTERED_IS_PAID_STATUS_UPDATE,
  ON_GET_ORDER_DETAILS_REQUEST,
  ON_ORDER_UPDATE_REQUEST,
  ON_ORDER_UPDATE_SUCCESS,
  ON_ORDER_UPDATE_FAIL,
  ON_ORDER_RECALC_LOADING,
  ON_ORDER_RECALC_NO_SAVE_SUCCESS,
  ON_ORDER_RECALC_NO_SAVE_FAIL,
  ON_CLEAR_RECALCULATED,
  CLEAR_CALCULATE_ERROR,
  CLEAR_ORDER_UPDATE_SUCCESS_FAIL_STATUS,
  ON_ORDER_LOCAL_UPDATE,
  ON_CREATE_ORDER_SUCCESS,
  ON_CREATE_ORDER_FAIL,
  ON_CREATE_ORDER,
  SEND_READY_IN_LOCKER_SMS_REQUEST,
  SEND_READY_IN_LOCKER_SMS_SUCCESS,
  SEND_READY_IN_LOCKER_SMS_FAIL,
  CLEAR_READY_IN_LOCKER_SMS,
  CLEAR_NEW_MAIL_ORDER,
  ON_CREATE_MAIL_ORDER_SUCCESS,
  ON_CREATE_MAIL_ORDER_FAIL,
  ON_ORDER_COUPON_REMOVE_SUCCESS,
  ON_ORDER_COUPON_REMOVE_FAIL,
  ON_ORDER_COUPON_ADD_FAIL,
  ON_ORDER_COUPON_ADD_SUCCESS,
  ON_GET_ORDER_BY_NUMBER_REQUEST,
  ON_GET_ORDER_BY_NUMBER_SUCCESS,
  ON_GET_ORDER_BY_NUMBER_FAIL,
  ON_START_ORDERS_LOADING,
  CLEAR_ORDER_DETAILS,
  CLEAR_ORDERS_LIST,
  ON_GET_ORDERS_BY_SUPPLIER_SUCCESS,
  ON_GET_ORDERS_BY_SUPPLIER_FAIL,
  ON_GET_ORDERS_BY_ROUTE_SUCCESS,
  ON_GET_ORDERS_BY_ROUTE_FAIL,
  CLEAR_ORDERS_BY_ROUTE,
  ON_GET_ORDERS_ITEMS_ORIGINAL_SUCCESS,
  ON_GET_ORDERS_ITEMS_ORIGINAL_FAIL,
  ON_ORDER_UPDATE_AFTER_LAUNDRY_SUCCESS,
  ON_ORDER_UPDATE_AFTER_LAUNDRY_FAIL,
  SEND_DELAY_SMS_SUCCESS,
  SEND_DELAY_SMS_FAIL,
  ON_UPDATE_ORDER_BAGS_SUCCESS,
  ON_UPDATE_ORDER_BAGS_FAIL,
  CLEAR_ORDER_BAGS_STATUS,
  ON_GET_CHEAP_SIM_ORDERS_SUCCESS,
  ON_GET_CHEAP_SIM_ORDERS_FAIL,
  ON_ORDER_CHEAPSIM_UPDATE_SUCCESS,
  ON_ORDER_CHEAPSIM_UPDATE_FAIL,
  CLEAR_CHEAPSIM_UPDATE,
  CLEAR_CHEAPSIM_LIST,
  CLEAR_NEW_LOCKER_TO_LOCKER_ORDER,
  ON_CREATE_LOCKER_TO_LOCKER_ORDER_SUCCESS,
  ON_CREATE_LOCKER_TO_LOCKER_ORDER_FAIL,
  ON_LOAD_NEW_MAIL_ORDER,
  ON_GET_ORDER_BY_PACKAGE_OR_NUMBER_REQUEST,
  ON_GET_ORDER_BY_PACKAGE_OR_NUMBER_SUCCESS,
  ON_GET_ORDER_BY_PACKAGE_OR_NUMBER_FAIL,
  CLEAR_ORDER_BY_PACKAGE_OR_NUMBER,
  ON_IN_LOCKER_EXTEND_SUCCESS,
  ON_IN_LOCKER_EXTEND_FAIL,
  CLEAR_IN_LOCKER_EXTEND,
  DELIVERY_LOGIN_CODE_RESET_SUCCESS,
  DELIVERY_LOGIN_CODE_RESET_FAIL,
  CLEAR_DELIVERY_LOGIN_CODE_RESET,
  CHANGE_TARGET_CUSTOMER_SUCCESS,
  CHANGE_TARGET_CUSTOMER_FAIL,
  CLEAR_CHANGE_TARGET_CUSTOMER,
  CLEAR_GET_ORDER_BY_NUMBER,
  CHANGE_TARGET_STATION_SUCCESS,
  CHANGE_TARGET_STATION_FAIL,
  CLEAR_CHANGE_TARGET_STATION,
  ON_RETURN_TO_SENDER_SUCCESS,
  ON_RETURN_TO_SENDER_FAIL,
  SET_ORDER_TRACKING_SUCCESS,
  SET_ORDER_TRACKING_FAIL,
  CLEAR_SET_ORDER_TRACKING,
  CHANGE_PACKAGE_NUMBER_REQUEST,
  CHANGE_PACKAGE_NUMBER_SUCCESS,
  CHANGE_PACKAGE_NUMBER_FAIL,
  CLEAR_CHANGE_PACKAGE_NUMBER,
  CHANGE_SOURCE_STATION_SUCCESS,
  CHANGE_SOURCE_STATION_FAIL,
  CLEAR_CHANGE_SOURCE_STATION,
  MARK_IN_ORDER_HISTORY_SUCCESS,
  MARK_IN_ORDER_HISTORY_FAIL,
  CLEAR_MARK_IN_ORDER_HISTORY,
} from "./types"

//export const printHydroLabel = (printObject) => {
import {
  printWhiteReceipt,
  printPinkLabel,
  printHydroLabel,
} from "./PrintActions"
import errorManager from "../utils/ErrorManager"
import { calcOrdersItemsCountForPrintLabel } from "../utils/Utillities"

export const fetchAllOrders = () => {
  return (dispatch) => {
    axios
      .get(api.getAllOrders)
      .then((res) => {
        dispatch({
          type: ON_GET_ORDERS_LIST_SUCCESS,
          payload: res.data,
        })
        console.log("SUCCESS ON GET ALL ORDERS")
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_ORDERS_LIST_FAIL,
          payload: err,
        })
        console.log("FAIL ON GET ALL ORDERS", err)
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const getOrdersBySupplier = (supplierId, orderStatus) => {
  return (dispatch) => {
    axios
      .get(api.getOrdersBySupplier(supplierId, orderStatus))
      .then((res) => {
        dispatch({
          type: ON_GET_ORDERS_BY_SUPPLIER_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_ORDERS_BY_SUPPLIER_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const getOrdersByRoute = (routeId) => {
  return (dispatch) => {
    axios
      .get(api.getOrdersByRoute(routeId))
      .then((res) => {
        dispatch({
          type: ON_GET_ORDERS_BY_ROUTE_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_ORDERS_BY_ROUTE_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const getOrderDetails = (orderId) => {
  return (dispatch) => {
    dispatch({ type: ON_GET_ORDER_DETAILS_REQUEST })
    axios
      .get(api.getOrderDetails(orderId))
      .then((res) => {
        dispatch({
          type: ON_GET_ORDER_DETAILS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
        //TODO DISPLAY ERROR MESSAGE
      })
  }
}

export const getOrderByNumberWithRoute = (orderNumber) => {
  return (dispatch) => {
    dispatch({ type: ON_GET_ORDER_BY_NUMBER_REQUEST })

    axios
      .get(api.getOrderByNumberWithRoute(orderNumber))
      .then((res) => {
        dispatch({
          type: ON_GET_ORDER_BY_NUMBER_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_GET_ORDER_BY_NUMBER_FAIL,
          payload: err,
        })
        //TODO DISPLAY ERROR MESSAGE
      })
  }
}

export const clearOrderByNumberWithRoute = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_GET_ORDER_BY_NUMBER,
    })
  }
}

export const onCreateNewOrder = () => {
  return (dispatch) => {
    dispatch({
      type: ON_CREATE_ORDER,
    })
  }
}

export const createNewOrder = (order) => {
  return (dispatch) => {
    let data = {}
    if (order.orderType === 5) {
      data = order
    } else {
      data = api.createNewOrderBody(order)
    }
    axios
      .post(api.createNewOrderUrl, data)
      .then((res) => {
        dispatch({
          type: ON_CREATE_ORDER_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
        console.log("createNewOrder", res.data)
        // CURRENTLY DON'T DISPATCH A THING HERE..
        //TODO DISPLAY SUCCESS MESSAGE, clear fields and GOTO OrderList
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_CREATE_ORDER_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
        //TODO DISPLAY ERROR MESSAGE, do not clear the fields.
      })
  }
}

export const createNewLockerToLockerOrder = (data) => {
  return (dispatch) => {
    axios
      .post(api.createNewOrderUrl, data)
      .then((res) => {
        dispatch({
          type: ON_CREATE_LOCKER_TO_LOCKER_ORDER_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
        console.log("createNewLockerToLockerOrder", res.data)
        // CURRENTLY DON'T DISPATCH A THING HERE..
        //TODO DISPLAY SUCCESS MESSAGE, clear fields and GOTO OrderList
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_CREATE_LOCKER_TO_LOCKER_ORDER_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
        //TODO DISPLAY ERROR MESSAGE, do not clear the fields.
      })
  }
}
export const clearNewLockerToLockerOrder = () => {
  return {
    type: CLEAR_NEW_LOCKER_TO_LOCKER_ORDER,
  }
}
export const updateOrderBags = (orderId, bagsQuantity) => {
  return (dispatch) => {
    axios
      .put(api.updateOrderBagsUrl(orderId, bagsQuantity))
      .then((res) => {
        dispatch({
          type: ON_UPDATE_ORDER_BAGS_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_UPDATE_ORDER_BAGS_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const clearOrderBagsStatus = () => {
  return {
    type: CLEAR_ORDER_BAGS_STATUS,
  }
}

export const updateOrderData = (
  updatedOrder,
  sendNotification,
  printPinkLbl,
  printReceipt
) => {
  return (dispatch) => {
    dispatch({ type: ON_ORDER_UPDATE_REQUEST })

    dispatch({ type: ON_ORDER_LOCAL_UPDATE, payload: updatedOrder })

    axios
      .put(api.updateOrder(updatedOrder.id, sendNotification), updatedOrder)
      .then((res) => {
        console.log("updateOrderData", res)
        const { orderNumber, firstName, lastName, orderType, accountType } =
          updatedOrder

        let printNum = calcOrdersItemsCountForPrintLabel(
          updatedOrder.orderItems
        )

        if (printPinkLbl) {
          const printObject = {
            name: firstName + " " + lastName,
            orderNumber,
            printNum,
            orderType,
          }
          //JSON.stringify( {"id":0,"functions":{"func0":{"setCharacterset":[1255]},"func1":{"printText":["ישראל ישראלי\n165487\n",0,0,false,false,false,0,1]},"func2":{"cutPaper":[1]}}});
          //dispatch(printPinkLabel(orderNumber, 'he'));

          dispatch(printHydroLabel(printObject))
        }

        if (printReceipt) {
          dispatch(printWhiteReceipt(updatedOrder))
        }

        dispatch({
          type: ON_ORDER_UPDATE_SUCCESS,
          payload: updatedOrder.id,
        })

        console.log(
          "updateOrderData --> reload edited order after update order (call getOrderDetails)"
        )
        getOrderDetails(updatedOrder.id)
      })
      .then(() => {
        console.log(
          "updateOrderData --> reload orders list after update order (call fetchAllOrders)"
        )
        fetchAllOrders()
      })
      .catch((err) => {
        console.log("updateOrderData", err)

        dispatch({ type: ON_ORDER_UPDATE_FAIL })
      })
  }
}

export const clearUpdateSuccessFailStatus = () => {
  return {
    type: CLEAR_ORDER_UPDATE_SUCCESS_FAIL_STATUS,
  }
}

export const onOrdersFilterByChange = (filterByVal) => {
  return {
    type: ON_ORDER_FILTER_BY_CHNAGE,
    payload: filterByVal,
  }
}

export const setExternalOrderToEdit = (order) => {
  return {
    type: SET_EXTERNAL_ORDER_TO_EDIT,
    payload: order,
  }
}

export const updateFiltererActiveTypes = (changedStatusIndex) => {
  return {
    type: ON_FILTERED_ACTIVE_TYPES_UPDATE,
    payload: changedStatusIndex,
  }
}

export const updateFiltererActiveStatuses = (changedStatusIndex) => {
  return {
    type: ON_FILTERED_ACTIVE_STATUSES_UPDATE,
    payload: changedStatusIndex,
  }
}

export const updateFiltererActiveStations = (changedStationNumber) => {
  return {
    type: ON_FILTERED_ACTIVE_STATIONS_UPDATE,
    payload: changedStationNumber,
  }
}

export const updateFiltererIsPaidStatus = (isPaidStatusUpdate) => {
  return {
    type: ON_FILTERED_IS_PAID_STATUS_UPDATE,
    payload: isPaidStatusUpdate,
  }
}

export const removeOrderCoupon = (orderId, order) => {
  return (dispatch) => {
    axios
      .put(api.removeOrderCoupon(orderId), order)
      .then((res) => {
        console.log("removeOrderCoupon", res)

        dispatch({
          type: ON_ORDER_COUPON_REMOVE_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log("removeOrderCoupon", err)
        dispatch({ type: ON_ORDER_COUPON_REMOVE_FAIL })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const addOrderCoupon = (couponId, order) => {
  return (dispatch) => {
    axios
      .put(api.addOrderCoupon(couponId), order)
      .then((res) => {
        console.log("addOrderCoupon success:", res)
        dispatch({
          type: ON_ORDER_COUPON_ADD_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log("addOrderCoupon fail:", err)
        dispatch({ type: ON_ORDER_COUPON_ADD_FAIL })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const sendReadyInLockerSMS = (orderId) => {
  return (dispatch) => {
    dispatch({ type: SEND_READY_IN_LOCKER_SMS_REQUEST })

    axios
      .get(api.sendInLockerSMS(orderId))
      .then((res) => {
        dispatch({
          type: SEND_READY_IN_LOCKER_SMS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: SEND_READY_IN_LOCKER_SMS_FAIL,
        })
      })
  }
}

export const sendInLockerReturnSMS = (orderId) => {
  return (dispatch) => {
    dispatch({ type: SEND_READY_IN_LOCKER_SMS_REQUEST })

    axios
      .get(api.sendInLockerReturnNotification(orderId))
      .then((res) => {
        dispatch({
          type: SEND_READY_IN_LOCKER_SMS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: SEND_READY_IN_LOCKER_SMS_FAIL,
        })
      })
  }
}

export const sendSMSForChangeStation = (orderId) => {
  return (dispatch) => {
    dispatch({ type: SEND_READY_IN_LOCKER_SMS_REQUEST })

    axios
      .get(api.sendSMSForChangeStation(orderId))
      .then((res) => {
        dispatch({
          type: SEND_READY_IN_LOCKER_SMS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: SEND_READY_IN_LOCKER_SMS_FAIL,
        })
      })
  }
}

export const clearReadyInLockerSMS = () => {
  return {
    type: CLEAR_READY_IN_LOCKER_SMS,
  }
}

export const createNewMailOrder = (order) => {
  return (dispatch) => {
    dispatch({ type: CLEAR_NEW_MAIL_ORDER })
    dispatch({ type: ON_LOAD_NEW_MAIL_ORDER })
    const orderType = order.orderType
    const body = [
      {
        mobilePhone: order.mobilePhone,
        stationNumber: order.stationNumber,
        packageNumber: order.packageNumber,
        firstName: order.firstName,
        lastName: order.lastName,
        orderStatus: order.orderStatus,
      },
    ]

    axios
      .post(api.createNewMailOrderUrl, body, {
        headers: { "Content-Type": "application/json; charset=UTF-8" },
        params: { orderType },
      })
      .then((res) => {
        if (res?.data[0].status == false) {
          dispatch({
            type: ON_CREATE_MAIL_ORDER_FAIL,
            payload: res.data[0]?.message,
          })
          errorManager.throwDispatch(dispatch, res)
          return
        }
        // const { orderType, accountType } = order
        dispatch({
          type: ON_CREATE_MAIL_ORDER_SUCCESS,
          payload: res.data,
        })
        // if (orderType == 2) {
        //   dispatch(
        //     printWhiteReceipt(
        //       res.data[0].orderNumber,
        //       orderType,
        //       accountType,
        //       "he"
        //     )
        //   )
        // }

        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .then(() => {
        console.log(
          "createNewMailOrder --> reload orders list after new mail order (call fetchAllOrders)"
        )
        fetchAllOrders()
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_CREATE_MAIL_ORDER_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const clearNewMailOrder = () => {
  return {
    type: CLEAR_NEW_MAIL_ORDER,
  }
}

export const onStartOrdersLoading = () => {
  return {
    type: ON_START_ORDERS_LOADING,
  }
}

export const clearOrderDetails = () => {
  return {
    type: CLEAR_ORDER_DETAILS,
  }
}

export const clearOrdersList = () => {
  return {
    type: CLEAR_ORDERS_LIST,
  }
}

export const clearOrdersByRoute = () => {
  return {
    type: CLEAR_ORDERS_BY_ROUTE,
  }
}

export const getOrderItemsOriginal = (orderId) => {
  return (dispatch) => {
    axios
      .get(api.getOrderItemsOriginal(orderId))
      .then((res) => {
        dispatch({
          type: ON_GET_ORDERS_ITEMS_ORIGINAL_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_ORDERS_ITEMS_ORIGINAL_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const UpdateOrderAfterReceivingLaundry = (order) => {
  return (dispatch) => {
    axios
      .put(api.UpdateOrderAfterReceivingLaundry(), order)
      .then((res) => {
        dispatch({
          type: ON_ORDER_UPDATE_AFTER_LAUNDRY_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_ORDER_UPDATE_AFTER_LAUNDRY_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const recalcOrderNoSave = (updatedOrder) => {
  return (dispatch) => {
    dispatch({ type: ON_ORDER_RECALC_LOADING })

    axios
      .put(api.recalcOrderNoSave(updatedOrder.id), updatedOrder)
      .then((res) => {
        console.log("recalcOrderNoSave")
        console.log(res)

        dispatch({
          type: ON_ORDER_RECALC_NO_SAVE_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log("recalcOrderNoSave", err)
        dispatch({ type: ON_ORDER_RECALC_NO_SAVE_FAIL })
      })
  }
}

export const clearCalculateError = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CALCULATE_ERROR })
  }
}

export const clearRecalculated = () => {
  return (dispatch) => {
    dispatch({ type: ON_CLEAR_RECALCULATED })
  }
}

export const sendDelaySMS = (orderId) => {
  return (dispatch) => {
    axios
      .post(api.sendDelaySMS(orderId))
      .then((res) => {
        dispatch({
          type: SEND_DELAY_SMS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: SEND_DELAY_SMS_FAIL,
        })
      })
  }
}

export const sendListDelaySMS = (listOfIds) => {
  return (dispatch) => {
    dispatch({ type: SEND_READY_IN_LOCKER_SMS_REQUEST })

    axios
      .post(api.sendListDelaySMS, listOfIds)
      .then((res) => {
        dispatch({
          type: SEND_READY_IN_LOCKER_SMS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: SEND_READY_IN_LOCKER_SMS_FAIL,
        })
      })
  }
}

export const notificateEndOfScanning = (orders) => {
  return (dispatch) => {
    axios
      .post(api.notificateEndOfScanning, orders)
      .then((res) => {
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const getCheapSimOrders = () => {
  return (dispatch) => {
    axios
      .get(api.getCheapSimOrders)
      .then((res) => {
        dispatch({
          type: ON_GET_CHEAP_SIM_ORDERS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_CHEAP_SIM_ORDERS_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const updateCheapSimOrder = (orderId, externalOrderNumber) => {
  return (dispatch) => {
    axios
      .put(api.updateCheapSimOrder(orderId, externalOrderNumber))
      .then((res) => {
        dispatch({
          type: ON_ORDER_CHEAPSIM_UPDATE_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_ORDER_CHEAPSIM_UPDATE_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const clearCheapSimUpdate = () => {
  return {
    type: CLEAR_CHEAPSIM_UPDATE,
  }
}

export const clearCheapSimList = () => {
  return {
    type: CLEAR_CHEAPSIM_LIST,
  }
}

export const getOrderByPackageOrNumber = (searchTerm) => {
  return (dispatch) => {
    dispatch({ type: ON_GET_ORDER_BY_PACKAGE_OR_NUMBER_REQUEST })

    axios
      .get(api.getOrderByPackageOrNumber(searchTerm))
      .then((res) => {
        dispatch({
          type: ON_GET_ORDER_BY_PACKAGE_OR_NUMBER_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_ORDER_BY_PACKAGE_OR_NUMBER_FAIL,
          payload: err,
        })
      })
  }
}

export const clearOrderByPackageOrNumber = () => {
  return {
    type: CLEAR_ORDER_BY_PACKAGE_OR_NUMBER,
  }
}

export const updateOrderStatusAndExtentionDate = (
  orderId,
  orderStatus,
  inLockerExtentionDate,
  isExtentionBilling
) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .post(
        api.updateOrderStatusAndExtentionDate(
          orderId,
          orderStatus,
          inLockerExtentionDate,
          isExtentionBilling
        ),
        options
      )
      .then((res) => {
        dispatch({
          type: ON_IN_LOCKER_EXTEND_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_IN_LOCKER_EXTEND_FAIL,
          payload: {
            errTitle: err.response.data.message,
            errText: err.response.status,
          },
        })
      })
  }
}

export const clearOrderInLockerExtend = () => {
  return {
    type: CLEAR_IN_LOCKER_EXTEND,
  }
}

export const resetDeliveryLoginCode = (orderId) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .post(api.resetDeliveryLoginCode(orderId), options)
      .then((res) => {
        dispatch({
          type: DELIVERY_LOGIN_CODE_RESET_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: DELIVERY_LOGIN_CODE_RESET_FAIL,
          payload: {
            errTitle: err.response.data.message,
            errText: err.response.status,
          },
        })
      })
  }
}

export const clearDeliveryLoginCodeReset = () => {
  return {
    type: CLEAR_DELIVERY_LOGIN_CODE_RESET,
  }
}

export const changeTargetCustomer = (orderId, userId) => {
  return (dispatch) => {
    axios
      .post(api.changeTargetCustomer(orderId, userId))
      .then((res) => {
        dispatch({
          type: CHANGE_TARGET_CUSTOMER_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: CHANGE_TARGET_CUSTOMER_FAIL,
          payload: {
            errTitle: err.response.data.message,
            errText: err.response.status,
          },
        })
      })
  }
}

export const clearChangeTargetCustomer = () => {
  return {
    type: CLEAR_CHANGE_TARGET_CUSTOMER,
  }
}

export const clearChangeTargetStation = () => {
  return {
    type: CLEAR_CHANGE_TARGET_STATION,
  }
}

export const setTargetStationChangeState = (
  orderId,
  targetStationId,
  isStationChangeBilling
) => {
  return (dispatch) => {
    //dispatch({ type: ON_GET_ORDER_DETAILS_REQUEST })
    axios
      .post(
        api.setTargetStationChangeState(
          orderId,
          targetStationId,
          isStationChangeBilling
        )
      )
      .then((res) => {
        dispatch({
          type: CHANGE_TARGET_STATION_SUCCESS,
          payload: res,
        })

        axios
          .get(api.getOrderDetails(orderId))
          .then((res) => {
            dispatch({
              type: ON_GET_ORDER_DETAILS_SUCCESS,
              payload: res.data,
            })
          })
          .catch((err) => {
            console.log(err)
          })
      })

      .catch((err) => {
        dispatch({
          type: CHANGE_TARGET_STATION_FAIL,
          payload: {
            errTitle: err?.response?.data?.message,
            errText: err?.response?.status,
          },
        })
      })
  }
}

export const startReturnToSender = (orderId) => {
  return (dispatch) => {
    dispatch({ type: ON_GET_ORDER_DETAILS_REQUEST })
    axios
      .get(api.startReturnToSender(orderId))
      .then((res) => {
        dispatch({
          type: ON_RETURN_TO_SENDER_SUCCESS,
          payload: res,
        })

        axios
          .get(api.getOrderDetails(orderId))
          .then((res) => {
            dispatch({
              type: ON_GET_ORDER_DETAILS_SUCCESS,
              payload: res.data,
            })
          })
          .catch((err) => {
            console.log(err)
          })
      })
      .catch((err) => {
        dispatch({
          type: ON_RETURN_TO_SENDER_FAIL,
          payload: {
            errTitle: err?.response?.data?.message,
            errText: err?.response?.status,
          },
        })
      })
  }
}

export const setOrderTracking = (orderId, isTracking, trackingNote) => {
  return (dispatch) => {
    const body = JSON.stringify(trackingNote)
    axios
      .post(api.setOrderTracking(orderId, isTracking), body, {
        headers: { "Content-Type": "application/json; charset=UTF-8" },
      })
      .then((res) => {
        dispatch({
          type: SET_ORDER_TRACKING_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: SET_ORDER_TRACKING_FAIL,
          payload: {
            errTitle: err?.response?.data?.message,
            errText: err?.response?.status,
          },
        })
      })
  }
}

export const clearOrderTracking = () => {
  return {
    type: CLEAR_SET_ORDER_TRACKING,
  }
}

export const setSourceStation = (orderId, targetStationId) => {
  return (dispatch) => {
    axios
      .post(api.setSourceStation(orderId, targetStationId))
      .then((res) => {
        dispatch({
          type: CHANGE_SOURCE_STATION_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: CHANGE_SOURCE_STATION_FAIL,
          payload: {
            errTitle: err?.response?.data?.message,
            errText: err?.response?.status,
          },
        })
      })
  }
}

export const clearSetSourceStation = () => {
  return {
    type: CLEAR_CHANGE_SOURCE_STATION,
  }
}

export const changePackageNumber = (orderId, packageNumber) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_PACKAGE_NUMBER_REQUEST })
    axios
      .post(api.changePackageNumber(orderId, packageNumber))
      .then((res) => {
        dispatch({
          type: CHANGE_PACKAGE_NUMBER_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: CHANGE_PACKAGE_NUMBER_FAIL,
          payload: {
            errTitle: err?.response?.data?.message,
            errText: err?.response?.status,
          },
        })
      })
  }
}

export const clearChangePackageNumber = () => {
  return {
    type: CLEAR_CHANGE_PACKAGE_NUMBER,
  }
}

export const markInOrderHistory = (orderNumber) => {
  return (dispatch) => {
    axios
      .post(api.markInOrderHistory(orderNumber))
      .then((res) => {
        dispatch({
          type: MARK_IN_ORDER_HISTORY_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: MARK_IN_ORDER_HISTORY_FAIL,
          payload: {
            errTitle: err?.response?.data?.message,
            errText: err?.response?.status,
          },
        })
      })
  }
}

export const clearMarkInOrderHistory = () => {
  return {
    type: CLEAR_MARK_IN_ORDER_HISTORY,
  }
}
