import React, { useEffect, useState } from "react"
import { BlueButton } from "../../../common/BlueButton"
import "./ScanOrderMobile.scss"

const ScannOrderMobile = (props) => {
  return (
    <section className="scanOrderMobile">
      <div className="title">סריקת הזמנות מובייל</div>
      <div>
        <BlueButton
          extraClass="btn-scan-mobile"
          onClick={() => props.history.push("/ScanOrderMobilePage")}
        >
          <p>סריקת הזמנות מובייל</p>
        </BlueButton>
      </div>
    </section>
  )
}

export default ScannOrderMobile
